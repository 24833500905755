const { web3 } = require('../web3');
const { env } = require('../config');

const NFTData = require(`./${env}/NFT.json`);
const seedRound = require(`./${env}/SEEDROUND.json`);
const usdt = require(`./${env}/USDT.json`);
const DAOData = require(`./${env}/DAO.json`);
const TokenData = require(`./${env}/HBurnToken.json`);
const NFTMarketpalce = require(`./${env}/HBurnMarketpalce.json`);

const HBurnFactoryData = require(`./${env}/HBurnFactory.json`);
const HBurnRouterData = require(`./${env}/HBurnRouter.json`);
const HBurnPairData = require(`./${env}/HBurnPair.json`);
const ERC20Data = require(`./${env}/ERC20.json`);


const NFTABI = NFTData['abi'];
const NFTAddress = NFTData['address'];
const NFTContract = new web3.eth.Contract(NFTABI, NFTAddress);

const SeedRountABI = seedRound['abi'];
const SeedRountAddress = seedRound['address'];
const SeedRountEthAddress = seedRound['addressEth'];
// const SeedRountContract = new web3.eth.Contract(SeedRountABI, SeedRountAddress);

const USDTABI = usdt['abi'];
const USDTAddress = usdt['address'];
// const USDTContract = new web3.eth.Contract(USDTABI, USDTAddress);

const DAOABI = DAOData['abi'];
const DAOAddress = DAOData['address'];
const DAOContract = new web3.eth.Contract(DAOABI, DAOAddress);

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const TokenContract = new web3.eth.Contract(TokenABI, TokenAddress);

const HBurnFactoryABI = HBurnFactoryData['abi'];
const HBurnFactoryAddress = HBurnFactoryData['address'];
const HBurnFactoryContract = new web3.eth.Contract(HBurnFactoryABI, HBurnFactoryAddress);

const HBurnRouterABI = HBurnRouterData['abi'];
const HBurnRouterAddress = HBurnRouterData['address'];
const HBurnRouterContract = new web3.eth.Contract(HBurnRouterABI, HBurnRouterAddress);

const HBurnPairABI = HBurnPairData['abi'];

const ERC20ABI = ERC20Data['abi'];


const NFTMarketpalceABI = NFTMarketpalce['abi'];
const NFTMarketpalcAddress = NFTMarketpalce['address'];
const NFTMarketpalcContract = new web3.eth.Contract(NFTMarketpalceABI, NFTMarketpalcAddress);


module.exports = {
  NFTContract,NFTABI,NFTAddress,
  SeedRountABI, SeedRountAddress, SeedRountEthAddress, 
  USDTAddress, USDTABI,
  DAOABI, DAOAddress, DAOContract,
  TokenABI, TokenAddress, TokenContract,
  HBurnFactoryABI, HBurnFactoryAddress, HBurnFactoryContract,
  HBurnRouterABI, HBurnRouterAddress, HBurnRouterContract,
  HBurnPairABI, ERC20ABI,
  NFTMarketpalceABI, NFTMarketpalcAddress, NFTMarketpalcContract
};
