import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams, Link } from "react-router-dom";
import { Tabs, Tab, Form, Modal, Button } from 'react-bootstrap';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';

import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { NFTMarketpalceABI, NFTMarketpalcAddress, NFTMarketpalcContract, NFTABI, TokenContract, TokenAddress } from "../../store/contract"
import { networkId, message, networkType } from "../../store/config";
import { getSingleNft, toggleLoader, listNft, unListNft, transferNft, createAuction, getBids, placeBid, endAuction } from "../../store/actions/Auth"

import ProgressBar from "@ramonak/react-progress-bar";

const bid = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/list.png";

const BuyNFT = (props) => {

    let [auctionTime, setAuctionTime] = useState();
    let [startTime, setStartTime] = useState();
    let [countDown, setCountDown] = useState(true);
    let [startcountDown, setStartCountDown] = useState(true);
    let [lastbid, setLastbid] = useState();
    let [receiverAddress, setReceiverAddress] = useState();
    let [amount, setAmount] = useState(1);
    let [price, setPrice] = useState();
    let [userNftHolding, setuserNftHolding] = useState({});
    let [isRequiredHBurn, setIsRequiredHBurn] = useState(false);
    const [imageError, setImageError] = useState(false);
    const { id } = useParams();
    const [key, setKey] = useState('home');

    const [transferModal, setTransferModal] = useState(false);
    const handleClose = () => setTransferModal(false);
    const handleShow = () => setTransferModal(true);

    const [auctionModal, setAuctionModal] = useState(false);
    const handleClose1 = () => setAuctionModal(false);
    const handleShow1 = () => setAuctionModal(true);

    useEffect(()=>{
        props.toggleLoader({status:true, message:"Load nft detail..."});
        props.getSingleNft({id})
    },[])

    useEffect(()=>{
        if(Object.keys(props.singleNft).length > 0) {
            setPrice(props.singleNft.price ? props.singleNft.price : 0)
            setuserNftHolding(props.singleNft);
            setLastbid(props.singleNft.price);
            props.getBids({nftObjId:id});
            setCountDown(true);
            handleClose();
            handleClose1();
        }
    },[props.singleNft]);

    const handleImageError = () => {
        setImageError(true);
    };

    function incrementCount() {
        let totalcount = amount + 1;
        if (totalcount > userNftHolding.mintAmount) return EventBus.publish("error", `You can add ${userNftHolding.mintAmount} NFT`);
        if (totalcount <= 0) setAmount(1);
        else setAmount(totalcount);
    }

    function decrementCount() {
        let totalcount = amount - 1;
        if (totalcount <= 0) setAmount(1);
        else setAmount(totalcount);
    }

    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 7) {
            setPrice(inputValue);
        }
    };

    const listNft = async () => {
        try {
            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!price) return EventBus.publish('error', `Please set price`);
            let { _id, tokenAddress, nftId, tokenType, users } = userNftHolding;

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            let Token = new web3.eth.Contract(NFTABI, tokenAddress);
            props.toggleLoader({ message: 'Approve for list nft...', status: true });
            // let newprice = await web3.utils.toWei(price.toString(), "ether");
            let newprice = (price*10**8).toString()
            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: tokenAddress,
                value: 0,
                gas: 3000000,
                data: Token.methods.approve(NFTMarketpalcAddress, nftId).encodeABI()
                   
            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    props.toggleLoader({ message: 'List nft...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: NFTMarketpalcAddress,
                        value: 0,
                        gas: 5000000,
                        data: NFTMarketpalcContract.methods.createMarketItem(tokenAddress, nftId, newprice, tokenType, users.publicAddress, isRequiredHBurn).encodeABI(),
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            setPrice("");
                            // price = await web3.utils.fromWei(price.toString(), "ether");
                            props.listNft({ nftObjId: _id, price, listAmount: amount, isRequiredHBurn })
                        }).on('error', e => {
                            setPrice("");
                            console.log("**************** e", e)
                            props.toggleLoader({ status: false });
                            EventBus.publish('error', ` ${e}`);
                        });
                }).on('error', e => {
                    setPrice("");
                    console.log("**************** e", e)
                    props.toggleLoader({ status: false });
                    EventBus.publish('error', ` ${e}`);
                });
        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const unListNft = async () => {
        try {
            let { _id, tokenAddress, nftId, tokenType, listAmount, users } = userNftHolding;
            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            props.toggleLoader({ message: 'Unlist nft...', status: true });
            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: NFTMarketpalcAddress,
                value: 0,
                gas: 5000000,
                data: NFTMarketpalcContract.methods.unListItems(tokenAddress, nftId, users.publicAddress).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    props.unListNft({ nftObjId: _id })
                }).on('error', e => {
                    props.toggleLoader({ status: false });
                    EventBus.publish('error', `${e}`);
                });
        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const buyNft = async () => {
        try {
            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            // if(!receiverAddress) return EventBus.publish('error',`Invalid Receiver Address`);
            let { _id, price, tokenAddress, nftId, tokenType, listAmount, users, isRequiredHBurn } = userNftHolding;
            let getBalance = 0
            if (isRequiredHBurn) {
                getBalance = await TokenContract.methods.balanceOf(props.publicAddress).call();
            } else {
                getBalance = await web3.eth.getBalance(props.publicAddress);
            }
            // let balanceInEther = await web3.utils.fromWei(getBalance.toString(), 'ether');


            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            getBalance = (Number(getBalance)/10**8).toString()
            console.log("***************** getBalance",getBalance)
            console.log("***************** price",price)
            if (Number(getBalance) < Number(price)) return EventBus.publish('error', `Insufficient Balance`);
            if (isRequiredHBurn) {
                let newprice = (price*10**8).toString()
                // let Token = new web3.eth.Contract(NFTABI,tokenAddress);
                props.toggleLoader({ message: 'Approve HBurn...', status: true });
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: TokenAddress,
                    value: 0,
                    gas: 3000000,
                    data:
                        TokenContract.methods.approve(NFTMarketpalcAddress, newprice).encodeABI()
                }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                        props.toggleLoader({ message: 'Buy nft...', status: true });
                        web3.eth.sendTransaction({
                            from: props.publicAddress,
                            to: NFTMarketpalcAddress,
                            value: 0,
                            gas: 3000000,
                            data:
                                NFTMarketpalcContract.methods.createMarketSalebyHBurn(tokenAddress, nftId, users.publicAddress).encodeABI()
                        }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                            .on('receipt', async receipt => {
                                props.transferNft({ nftObjId: _id, receiverAddress: props.publicAddress, mintAmount: listAmount })
                            }).on('error', e => {
                                props.toggleLoader({ status: false });
                                EventBus.publish('error', `${e}`);
                            });
                    })

            } else {
                props.toggleLoader({ message: 'Buy nft...', status: true });
                let newprice = await web3.utils.toWei(price.toString(), 'ether');
                console.log("*************** tokenAddress, nftId",tokenAddress, nftId)
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: NFTMarketpalcAddress,
                    value: newprice,
                    gas: 3000000,
                    data: NFTMarketpalcContract.methods.createMarketSale(tokenAddress, nftId, users.publicAddress).encodeABI()
                }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                        props.transferNft({ nftObjId: _id, receiverAddress: props.publicAddress, mintAmount: listAmount })
                    }).on('error', e => {
                        props.toggleLoader({ status: false });
                        EventBus.publish('error', `${e}`);
                    });
            }

        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const transferNft = async (e) => {
        try {
            e.preventDefault()
            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!receiverAddress) return EventBus.publish('error', `Invalid Receiver Address`);
            let { _id, price, tokenAddress, nftId, tokenType, listAmount, users } = userNftHolding;

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);
            if (users.publicAddress.toLowerCase() == receiverAddress.toLowerCase()) return EventBus.publish('error', `NFT can't be transfer with the owner address`);

            let Token = new web3.eth.Contract(NFTABI, tokenAddress);
            props.toggleLoader({ message: 'Transfer nft...', status: true });
            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: tokenAddress,
                value: 0,
                gas: 5000000,
                data: Token.methods.transferFrom(props.publicAddress, receiverAddress, nftId).encodeABI()

            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    props.transferNft({ nftObjId: _id, receiverAddress, mintAmount: amount });
                    handleClose();
                }).on('error', e => {
                    props.toggleLoader({ status: false });
                    EventBus.publish('error', `${e}`);
                });
        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const createAuction = async (e) => {
        try {
            e.preventDefault();
            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!auctionTime) return EventBus.publish('error', `Please set auction time`);
            const inputAuctionTime = new Date(auctionTime);
            const inputStartTime = new Date(startTime);
            const currentDateTime = new Date();
            if (inputAuctionTime <= currentDateTime) return EventBus.publish('error', `Auction time must be grather then current time`);
            // if (inputAuctionTime <= inputStartTime) return EventBus.publish('error',`Auction end time must be grather then start time`);
            const timestamp = Date.parse(auctionTime) / 1000;
            const timestampstartTime = Date.parse(startTime) / 1000;
            if (!price) return EventBus.publish('error', `Please set price`);
            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            let { _id, tokenAddress, nftId, tokenType, users, isRequiredHBurn } = userNftHolding;

            let getBalance = 0;
            if (isRequiredHBurn) {
                getBalance = await TokenContract.methods.balanceOf(props.publicAddress).call();
            } else {
                getBalance = await web3.eth.getBalance(props.publicAddress);
            }

            // let balanceInEther = await web3.utils.fromWei(getBalance.toString(), 'ether');

            // if(Number(balanceInEther) < Number(price)) return EventBus.publish('error',`Insufficient Balance`); 



            // let {} = await makeTokens();
            let Token = new web3.eth.Contract(NFTABI, tokenAddress);
            let newprice = await web3.utils.toWei(price.toString(), "ether");
            // let newprice = (price*10**8).toString()
            props.toggleLoader({ message: 'Approve for auction...', status: true });

            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: tokenAddress,
                value: 0,
                gas: 5000000,
                data: Token.methods.approve(NFTMarketpalcAddress, nftId).encodeABI()

            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    props.toggleLoader({ message: 'Auction setup...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: NFTMarketpalcAddress,
                        value: 0,
                        data: NFTMarketpalcContract.methods.createAuction(tokenAddress, nftId, newprice, timestamp, tokenType, users.publicAddress, isRequiredHBurn).encodeABI(),
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            console.log("******************** receipt", receipt);
                            setPrice("");
                            // price = await web3.utils.fromWei(price.toString(), "ether");
                            props.createAuction({ nftObjId: _id, price, bidAmount: amount, bidTime: timestamp, startTime: timestamp, isRequiredHBurn })
                        }).on('error', e => {
                            setPrice("");
                            handleClose()
                            handleClose1()
                            props.toggleLoader({ status: false });
                            EventBus.publish('error', `${e}`);
                        });
                }).on('error', e => {
                    setPrice("");
                    console.log("**************** e", e)
                    props.toggleLoader({ status: false });
                    EventBus.publish('error', `${e}`);
                });
        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const endAuction = async () => {
        try {
            let { _id, tokenAddress, nftId, tokenType, users, isRequiredHBurn } = userNftHolding;

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            if (users.publicAddress.toLowerCase() != props.publicAddress.toLowerCase()) return EventBus.publish('error', `Only owner can end auction`);
            // console.log("**************** isRequiredHBurn", isRequiredHBurn)            
            props.toggleLoader({ message: 'End auction ...', status: true });
            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: NFTMarketpalcAddress,
                value: 0,
                gas: 5000000,
                data: isRequiredHBurn ?
                    NFTMarketpalcContract.methods.endAuctionbyHBurn(tokenAddress, nftId, users.publicAddress).encodeABI()
                    :
                    NFTMarketpalcContract.methods.endAuction(tokenAddress, nftId, users.publicAddress).encodeABI()
            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    setPrice("");
                    props.endAuction({ nftObjId: _id })
                }).on('error', e => {
                    setPrice("");
                    console.log("**************** e", e)
                    props.toggleLoader({ status: false });
                    EventBus.publish('error', `${e}`);
                });
        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const placeBid = async () => {
        try {
            let { _id, tokenAddress, nftId, tokenType, users, isRequiredHBurn } = userNftHolding;
            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            if (users.publicAddress.toLowerCase() == props.publicAddress.toLowerCase()) return EventBus.publish('error', `owner can't be place auction`);
            if (Number(price) <= Number(lastbid)) return EventBus.publish('error', `Price must be grather then last bid`);
            let newprice = await web3.utils.toWei(price.toString(), "ether");
            // let newprice = (price*10**8).toString()
            let balanceInEther = 0;
            if (isRequiredHBurn) {
                balanceInEther = await TokenContract.methods.balanceOf(props.publicAddress).call();
            } else {
                balanceInEther = await web3.eth.getBalance(props.publicAddress);
            }
            if (Number(balanceInEther) < Number(newprice)) return EventBus.publish('error', `Insufficient Balance`);

            if (isRequiredHBurn) {
                let Token = new web3.eth.Contract(NFTABI, tokenAddress);
                props.toggleLoader({ message: 'Approve HBurn ...', status: true });
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: TokenAddress,
                    value: 0,
                    gas: 5000000,
                    data:
                        TokenContract.methods.approve(NFTMarketpalcAddress, newprice).encodeABI()
                }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                        props.toggleLoader({ message: 'Place bid ...', status: true });
                        web3.eth.sendTransaction({
                            from: props.publicAddress,
                            to: NFTMarketpalcAddress,
                            value: 0,
                            gas: 5000000,
                            data: NFTMarketpalcContract.methods.placeBidbyHBurn(tokenAddress, nftId, users.publicAddress, newprice).encodeABI()
                        }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                            .on('receipt', async receipt => {
                                console.log("******************** receipt", receipt);
                                setPrice("");
                                // price = await web3.utils.fromWei(price.toString(), "ether");
                                // price = price/10**8
                                props.placeBid({ nftObjId: _id, price, bidderAddress: props.publicAddress })
                            }).on('error', e => {
                                setPrice("");
                                console.log("**************** e", e)
                                props.toggleLoader({ status: false });
                                EventBus.publish('error', `${e}`);
                            });
                    })
            } else {
                props.toggleLoader({ message: 'Place bid ...', status: true });
                web3.eth.sendTransaction({
                    from: props.publicAddress,
                    to: NFTMarketpalcAddress,
                    value: newprice,
                    gas: 5000000,
                    data: NFTMarketpalcContract.methods.placeBid(tokenAddress, nftId, users.publicAddress).encodeABI(),
                }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                    .on('receipt', async receipt => {
                        console.log("******************** receipt", receipt);
                        setPrice("");
                        // price = await web3.utils.fromWei(price.toString(), "ether");
                        // price = price/10**8
                        props.placeBid({ nftObjId: _id, price, bidderAddress: props.publicAddress })
                    }).on('error', e => {
                        setPrice("");
                        console.log("**************** e", e)
                        props.toggleLoader({ status: false });
                        EventBus.publish('error', `${e}`);
                    });
            }

        } catch (error) {
            console.log("********************** error", error);
            props.toggleLoader({ status: false });
            return EventBus.publish('error', error);
        }
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            setCountDown(false);
        } else {
            // Render a countdown
            return <>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</>;

        }
    };

    const rendererStartTime = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            setStartCountDown(false);
            return <>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</>;
        } else {
            // Render a countdown
            return <>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</>;

        }
    };


    return (
        <div className="wrapper">
            <Navbar />

            <section className='buy-nft'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <div className="img-wrap">
                                {/* <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/banner-bg.png" alt="" /> */}
                                <img src={imageError ? alternate : userNftHolding?.image} onError={handleImageError} alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6 ps-xl-2">
                            <div className="head">
                                {/* <span>About this collection</span> */}

                                <h1>{userNftHolding?.title ? userNftHolding?.title : "-"}</h1>
                            </div>

                            <div className="item-interaction">
                                <div className="item-views">
                                    <span>HBAR</span>
                                    <span>ERC 721</span>
                                </div>

                                {/* <div className="share-option">
                                    <button><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/send.png" alt="" /></button>
                                    <button><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/info.png" alt="" /></button>
                                </div> */}
                            </div>
                            <p>{userNftHolding?.desc ? userNftHolding?.desc : "-"}</p>

                            {/* <div className="progress-bar-wrap">
                                <div className="progressbar-head">
                                    <span><b>83.1%</b> Minted</span>

                                    <span>120/154</span>                       
                                </div>
                                <ProgressBar className="progress-bar" completed={60} />
                            </div> */}

                            <div className="public-stage-wrapper">
                                {/* <h3>
                                    Public Stage                 
                                </h3> */}


                                {/* <div className="limit-wrap"> */}
                                {/* <div className="info">
                                        <p>0.035 EETH</p>

                                        <span>Limit 25 per wallet</span>
                                    </div> */}

                                {/* <div className="form-group inc-dec-wrap"> */}
                                {/* <img className="increment" onClick={decrementCount} src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/decrement.png" alt="" /> */}

                                {/* <input type="number" value={count} placeholder="Add amount" /> */}

                                {/* <img  className="decrement" onClick={incrementCount} src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/increment.png" alt="" />
                                    </div> */}

                                {/* <button className="common-btn">MAX <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/max.png" alt="" /></button> */}
                                {/* </div> */}

                                {
                                    userNftHolding?.status == "auction" ?
                                        <div className="price d-flex mb-4">
                                            <div className="price-card mb-4 w-50">
                                                <h5 className="mb-1">Current Bid</h5>
                                                <p><b>{userNftHolding?.price ? userNftHolding.price : 0}
                                                    {userNftHolding?.isRequiredHBurn ? " HBurn " : " HBAR "}
                                                </b></p>
                                            </div>
                                            <div className="price-card mb-4 w-50">
                                                <h5 className="mb-1">{"Countdown"}</h5>
                                                {/* {countDown == true && <span><b><Countdown date={Date.now() + 30000000000000} renderer={renderer} /> </b></span> } */}
                                                {/* {countDown == true && <span><b>{userNftHolding?.bidTime ? <Countdown date={Date.now() + parseInt((Number(userNftHolding.bidTime) * 1000) - Date.now())} renderer={renderer} /> : "00 : 00 : 00 : 00" } </b></span> } */}
                                                {
                                                    //    startcountDown == true ?
                                                    //    countDown == true &&
                                                    //    userNftHolding &&
                                                    //    <p><b> 
                                                    //        {userNftHolding?.startTime ? <Countdown date={Date.now() + parseInt((Number(userNftHolding.startTime) * 1000) - Date.now())} renderer={rendererStartTime} /> : "00 : 00 : 00 : 00" }
                                                    //    </b></p> 
                                                    //    :
                                                    countDown == true &&
                                                    userNftHolding &&
                                                    <p><b>
                                                        {userNftHolding?.bidTime ? <Countdown date={Date.now() + parseInt((Number(userNftHolding.bidTime) * 1000) - Date.now())} renderer={renderer} /> : "00 : 00 : 00 : 00"}
                                                    </b></p>
                                                }
                                                {countDown == false && <p><b>Auction Ended</b></p>}
                                            </div>
                                        </div>
                                        :
                                        <div className="price mb-4">
                                            <div className="price-card">
                                                <h5 className="mb-1">Current Price</h5>
                                                <p><b>{userNftHolding?.price}
                                                    {userNftHolding?.isRequiredHBurn ? " HBurn " : " HBAR "}
                                                </b></p>
                                            </div>
                                        </div>
                                    //  :

                                }

                                {/* set price */}
                                {userNftHolding.isMarketItem == true && userNftHolding.status == "auction" &&
                                    <div className="price mb-4">
                                        <div className="price-card">
                                            <h5 className="mb-2">Set Price</h5>
                                            <div className="form-group">
                                                <input type="number" placeholder="set price 0.01" onChange={handlePriceChange} value={price} maxLength={5} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userNftHolding.isMarketItem !== true && userNftHolding.status == "buy" &&
                                    <div className="price">
                                        <div className="price-card">
                                            <h5 className="mb-2">Set Price</h5>
                                            <div className="form-group">
                                                <input type="number" placeholder="set price 0.01" onChange={handlePriceChange} value={price} maxLength={5} />
                                            </div>
                                            <h5 className="mb-1">Select option</h5>
                                            <input type="radio" id="tokenType1" name="tokenType" checked={isRequiredHBurn ? true : false} value={isRequiredHBurn ? "HBurn" : ""} onChange={e => { setIsRequiredHBurn(true) }} />
                                            <label for="tokenType1">HBurn</label>
                                            <input type="radio" id="tokenType2" name="tokenType" checked={!isRequiredHBurn ? true : false} value={!isRequiredHBurn ? "HBAR" : ""} onChange={e => { setIsRequiredHBurn(false) }} />
                                            <label for="tokenType2">HBAR</label>
                                        </div>
                                    </div>
                                }
                                {/* {userNftHolding?.status == "auction" &&
                                               <div className="price">
                                                   <div className="price-card">
                                                       <h5 className="mb-1">Set Price</h5>
                                                       <input type="number" placeholder="set price 0.01" onChange={handlePriceChange} value={price} maxLength={5}/>
                                                   </div>
                                               </div>
                                           } */}

                                {userNftHolding?.tokenType == "erc1155" && userNftHolding?.isMarketItem == false && userNftHolding?.mintAmount !== 0 &&
                                    <div className="public-stage-wrapper">
                                        <Form className="inner">
                                            <div className="limit-wrap">
                                                <div className="info">
                                                    <p>
                                                        {isRequiredHBurn ? "HBurn" : "ABR"}
                                                    </p>

                                                    <span>Set Amount</span>
                                                </div>

                                                <Form.Group className="form-group">
                                                    <img className="increment" onClick={decrementCount} src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/decrement.png"} alt="" />

                                                    <Form.Control type="number" value={amount} />

                                                    <img className="decrement" onClick={incrementCount} src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/increment.png"} alt="" />
                                                </Form.Group>

                                                {/* <a className="common-btn white" onClick={maxNFT}>Max</a> */}
                                            </div>
                                            {/* <a className="common-btn" onClick={mintNFT}>Mint</a> */}
                                        </Form>
                                    </div>
                                }
                                {userNftHolding?.status == "auction" &&
                                    <div className="tabs-wrapper">
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                        >
                                            <Tab eventKey="home" title="Bid History">
                                                {props.setBids.length > 0 ?
                                                    props.setBids.map(item =>
                                                        <ul className='bid-list'>
                                                            <li>
                                                                <div className="thumbnail">
                                                                    <img src={item['image'] ? item['image'] : "https://ox-burn-assets.s3.us-east-2.amazonaws.com/bid-profile.png"} alt="" />
                                                                </div>
                                                                <div className="content">
                                                                    <h5>{item['name'] ? item['name'] : "-"}</h5>
                                                                    <p>{moment(new Date(item['createdAt']), "YYYYMMDD").fromNow()}</p>
                                                                    <span><b>
                                                                        {item['price']} {userNftHolding?.isRequiredHBurn ? " HBurn " : " HBAR "}
                                                                    </b></span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )
                                                    :
                                                    <ul className='bid-list'>
                                                        <li>
                                                            <div style={{ color: "white" }}>
                                                                <span>No Bids</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                }
                                            </Tab>
                                        </Tabs>
                                    </div>
                                }
                                {props.match.params && userNftHolding.status == "auction"
                                    ?
                                    countDown == false
                                        ?
                                        props.publicAddress && props.match.params && userNftHolding.users.publicAddress.toLowerCase() == props.publicAddress.toLowerCase() &&
                                        <button className='bid-btn common-btn pr' onClick={endAuction}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Auction Ended</button>
                                        :
                                        props.publicAddress && props.match.params && userNftHolding.users && userNftHolding.users.publicAddress.toLowerCase() !== props.publicAddress.toLowerCase() &&
                                        <button className='bid-btn common-btn' onClick={placeBid}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Place a bid</button>
                                    :
                                    props.publicAddress && props.match.params && userNftHolding.users && userNftHolding.users.publicAddress.toLowerCase() == props.publicAddress.toLowerCase()
                                        ?
                                        props.match.params && userNftHolding.isMarketItem == true
                                            ?
                                            <button className='bid-btn common-btn' onClick={unListNft}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/list.png" alt="" />Unlist Nft</button>
                                            :
                                            props.match.params && userNftHolding.tokenType == "erc1155" && props.match.params && userNftHolding.isMarketItem == false && props.match.params && userNftHolding.mintAmount !== 0 ?
                                                <>
                                                    <button className='bid-btn common-btn' onClick={listNft}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/list.png" alt="" />List Nft</button>
                                                    <button className='bid-btn common-btn' onClick={handleShow}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Transfer NFT</button>
                                                    <button className='bid-btn common-btn' onClick={handleShow1}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Auction NFT</button>
                                                </>
                                                :
                                                props.match.params && userNftHolding.tokenType == "erc721" && props.match.params && userNftHolding.isMarketItem == false &&
                                                <>
                                                    <button className='bid-btn common-btn' onClick={listNft}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />List Nft</button>
                                                    <button className='bid-btn common-btn' onClick={handleShow}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Transfer NFT</button>
                                                    <button className='bid-btn common-btn' onClick={handleShow1}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Auction NFT</button>
                                                </>
                                        :
                                        props.match.params && userNftHolding.isMarketItem == true
                                            ?
                                            <button className='bid-btn common-btn' onClick={buyNft}><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" />Buy</button>
                                            :
                                            <button className='bid-btn common-btn'>Not Listed</button>
                                }
                            </div>

                            {/* <button className="common-btn bid-btn"> <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/list.png" alt="" /></button> */}
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

            <Modal className="common-modal signin" show={transferModal} onHide={handleClose}>
                <button onClick={handleClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png" />
                </button>

                <Modal.Header closeButton>
                    Receiver Address
                </Modal.Header>

                <form onSubmit={transferNft}>
                    <div class="mb-3">
                        <input placeholder="Wallet Address" type="text" id="formBasicAddress" class="form-control" onChange={e => setReceiverAddress(e.target.value)} value={receiverAddress} />
                    </div>

                    <div>
                        <button className="common-btn" onClick={transferNft}>Transfer</button>
                    </div>
                </form>
            </Modal>

            <Modal className="common-modal signin" show={auctionModal} onHide={handleClose1}>
                <button onClick={handleClose1} type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png" />
                </button>

                <Modal.Header closeButton>
                    Set Time and Date
                </Modal.Header>

                <form onSubmit={createAuction}>
                    {/* <label>Start Time</label>
                <div class="form-group">
                    <input placeholder="Date and Time" type="datetime-local" id="formBasicTime" class="form-control" onChange={e=>setStartTime(e.target.value)}/>
                </div> */}
                    <label>Auction Time</label>
                    <div class="form-group">
                        <input placeholder="Date and Time" type="datetime-local" id="formBasicTime" class="form-control" onChange={e => setAuctionTime(e.target.value)} />
                    </div>

                    <div>
                        <button className="common-btn" onClick={createAuction}>Set Auction</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = { getSingleNft, toggleLoader, listNft, unListNft, transferNft, createAuction, getBids, placeBid, endAuction };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, singleNft, publicAddress, setBids, } = Auth;
    return { isLogin, singleNft, publicAddress, setBids }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyNFT);
