import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import 'react-responsive-modal/styles.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { toFixed } from "../../store/numberFormat";
import { networkId, message } from "../../store/config";
import UnknownLogo from "../../static/images/bookmark.png";
import { toggleLoader, setAddress } from "../../store/actions/Auth";
import { ERC20ABI, HBurnFactoryContract, HBurnPairABI, HBurnRouterAddress, HBurnRouterContract, TokenContract } from '../../store/contract/index';


const Staking = (props) => {

    let [stakeModal, setStakeModal] = useState(false);
    let [stakeModal2, setStakeModal2] = useState(false);
    let [unstakeModal, setUnstakeModal] = useState(false);
    let [unstakeModal2, setUnstakeModal2] = useState(false);

    return (
        <div className="wrapper">
            <Navbar />

            <section className="explore staking">
                <div className="container">
                    <div className="inner">
                        <div className="head text-center">
                            <h1>Staking</h1>
                        </div>

                        <div class="collection-wrap row">
                            <div class="col-md-6">
                                <div className="box">
                                    <div className="img-wrap">
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn_NFT-gif.gif" alt=" " />
                                    </div>

                                    <div class="btn-wrap">
                                        <button onClick={() => setStakeModal(true)} className="common-btn" >Stake</button>
                                        <button onClick={() => setUnstakeModal(true)} className="common-btn" >Unstake</button>
                                        <button className="common-btn" >Claim</button>
                                    </div>

                                    <div class="info-box">
                                        <div>
                                            <p>APY percentage</p>
                                            <p>6%</p>
                                        </div>

                                        <div>
                                            <p>Monthly reward</p>
                                            <p>50 REFI</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div className="box">
                                    <div className="img-wrap">
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn96.png" alt="" />
                                    </div>

                                    <div class="btn-wrap">
                                        <button onClick={() => setStakeModal2(true)} className="common-btn">Stake</button>
                                        <button onClick={() => setUnstakeModal2(true)} className="common-btn">Unstake</button>
                                        <button className="common-btn">Claim</button>
                                    </div>

                                    <div class="info-box">
                                        <div>
                                            <p>APY percentage</p>
                                            <p>6%</p>
                                        </div>

                                        <div>
                                            <p>Monthly reward</p>
                                            <p>50 REFI</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

              <Modal
                open={stakeModal}
                onClose={() => setStakeModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setStakeModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Stake your NFT</h2>

                    <div className='form-group'>
                        <select >
                            <option>BNB</option>
                            <option>ETH</option>
                            <option>AVAX</option>
                            <option>XDC</option>
                        </select>
                    </div>

                    <button className="common-btn"> Confirm</button>
                    <button className="common-btn"> Cancel</button>
                </div>
              </Modal>

              <Modal
                open={stakeModal2}
                onClose={() => setStakeModal2(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setStakeModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Stake your NFT</h2>

                    <div className='form-group'>
                        <select >
                            <option>BNB</option>
                            <option>ETH</option>
                            <option>AVAX</option>
                            <option>XDC</option>
                        </select>
                    </div>

                    <button className="common-btn"> Confirm</button>
                    <button className="common-btn"> Cancel</button>
                </div>
              </Modal>

              <Modal
                open={unstakeModal}
                onClose={() => setUnstakeModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setUnstakeModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Unstake your NFT</h2>

                    <div className='form-group'>
                        <select >
                            <option>BNB</option>
                            <option>ETH</option>
                            <option>AVAX</option>
                            <option>XDC</option>
                        </select>
                    </div>

                    <button className="common-btn"> Confirm</button>
                    <button className="common-btn"> Cancel</button>
                </div>
              </Modal>

              <Modal
                open={unstakeModal2}
                onClose={() => setUnstakeModal2(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setUnstakeModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Unstake your NFT</h2>

                    <div className='form-group'>
                        <select >
                            <option>BNB</option>
                            <option>ETH</option>
                            <option>AVAX</option>
                            <option>XDC</option>
                        </select>
                    </div>

                    <button className="common-btn"> Confirm</button>
                    <button className="common-btn"> Cancel</button>
                </div>
              </Modal>


        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(Staking);