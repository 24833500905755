import { PURGE } from "redux-persist";
import { tokenVerify } from "../jwtVerify";
import { setToken } from "../axios";

let initialState = {
  publicAddress: localStorage.getItem("publicAddress"),
  setLoader: { message: 'Please Wait...', status: false },
  kybValue: [],
  kybStatus: false,
  kybSubmitted: false,
  lotteryList: [],
  uriData: [],
  investerData: [],
  daoListHBurn: [],
  proposalListHBurn: [],
  seeds: 0,
  price: {ETH:0,HBAR:0},

  isLogin: tokenVerify() ? true : false,
  auth: localStorage.getItem("token"),
  chain: localStorage.getItem("chain") ? localStorage.getItem("chain") : 296,
  isLoader: { message: "Please Wait...", status: false },
  network: localStorage.getItem("network") ? localStorage.getItem("network") : "hedera",
  nonce: "",
  isNonce: true,
  isChangeNetwork: false,
  userCollections: [],
  collections: [],
  singleCollection: {},
  singleNft:{},
  userData:{},
  explore:[],
  userNfts:[],
  userFavitems:[],
  setBids:[],
  setAuctions:[],
  setAllNfts:[],
  category:[],
  singleCollectionDetails:{}
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case "SET_LOGIN":
      localStorage.setItem("token", payload);
      return {
        ...state,
        auth: payload,
        isLogin: true,
        isNonce: true
      };

     case "SET_NETWORK":
      localStorage.setItem("network", payload)
      return {
        ...state,
        network: payload,
        isChangeNetwork: true
      };

    case "SET_USER":
      return {
        ...state,
        userData: payload,
      };

    case "SET_NONCE":
      return {
        ...state,
        nonce: payload,
        isChangeNetwork: true,
        isNonce: false
      }
    case "SET_CHANGE_NETWORK":
      return {
        ...state,
        isChangeNetwork: payload
      };

    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("publicAddress");
      localStorage.removeItem("chain");
      localStorage.removeItem("network");
      setToken();
      return {
        ...state,
        publicAddress: "",
        auth: "",
        isLogin: false,
      };

    case 'SET_INVESTER_DATA':
      return {
        ...state,
        investerData: payload
      }

    case 'SET_SEED':
      return {
        ...state,
        seeds: payload
      }

    case 'SET_PRICE':
      return {
        ...state,
        price: payload
      }

    case 'SET_ADDRESS':
      localStorage.setItem("publicAddress", typeof payload == "string" ? payload : payload.publicAddress);
      return {
        ...state,
        publicAddress: typeof payload == "string" ? payload : payload.publicAddress,
      };

    case "SET_ADDRESS":
        
      if(typeof payload == "string" ) localStorage.setItem("publicAddress", payload)
      else {
        localStorage.setItem("publicAddress", payload['publicAddress'])
        localStorage.setItem("chain", payload['chain'])
      }
      return {
        ...state,
        publicAddress: typeof payload == "string" ? payload: payload['publicAddress'],
        chain: typeof payload == "string"? 296 :payload['chain']
    };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'SET_LOTTERY':
      return {
        ...state,
        lotteryList: payload,
      };

    case "SET_URI_DATA":
      return {
        ...state,
        uriData: payload,
      };

    case 'KYB_RESPONSE':
      console.log("*** payload", payload);
      return {
        ...state,
        kybSubmitted: payload,
      };

    case 'SET_KYB_STATUS':
      return {
        ...state,
        kybStatus: payload,
      };

    case 'SET_KYB':
      return {
        ...state,
        kybValue: payload,
      };

    case "SET_HBURN_DAO":
      return {
        ...state,
        daoListHBurn: payload,
      };

    case "SET_HBURN_PROPOSALS":
      return {
        ...state,
        proposalListHBurn: payload,
      };

    case "SET_CATEGORY":
      return {
        ...state,
        category: payload
      }

    /*========== COLLECTIONS REDUCERS ============= */
    case "SET_SINGLE_NFT":
      return {
        ...state,
        singleNft: payload,
      };
    /*========== COLLECTIONS REDUCERS ============= */
    case "SET_COLLECTION":
      return {
        ...state,
        userCollections: payload
      }

    case "SET_SINGLE_COLLECTION":
      return {
        ...state,
        singleCollection: payload
      }

    case "SET_SINGLE_COLLECTION_DETAILS":
      return {
        ...state,
        singleCollectionDetails: payload
      }

    case "SET_ALL_COLLECTION":
      return {
        ...state,
        collections: payload
      }

    case "SET_EXPLORE":
      return {
        ...state,
        explore: payload
      }

    case "SET_USER_NFTS":
      return {
        ...state,
        userNfts: payload
      }

    case "SET_USER_FAV_ITEMS":
      return {
        ...state,
        userFavitems: payload
      }

    case "SET_BIDS":
      return {
        ...state,
        setBids: payload
      }

    case "SET_AUCTIONS":
      return {
        ...state,
        setAuctions: payload
      }

    case "SET_ALL_NFTS":
      return {
        ...state,
        setAllNfts: payload
      }
    /*========== ACTIVITY REDUCERS ============= */
    case "SET_ALL_ACTIVITY":
      return {
        ...state,
        allActivity: payload,
      };

    case "SET_NFT_ACTIVITY":
      return {
        ...state,
        nftActivity: payload,
      };

    default:
      return state;
  }
};

export default Auth;