import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, setAddress } from "../../store/actions/Auth";
import { NFTABI, LotteryABI, CIFITokenABI } from '../../store/contract/index';


const PrivateSale = (props) => {

    const [days, setDays] = React.useState(0);
    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);

    const deadline = "September, 1, 2024";

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();    

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    React.useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="wrapper">
            <header>
                <div class="container">
                    <nav class="navbar justify-content-center">
                        <a href="/" class="logo" >
                            <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/logo.png" alt="" />
                        </a>
                    </nav>
                </div>
            </header>
            
            <section className="explore text-center">
                <h2>PRIVATE SALE STARTING IN</h2>

                <div className="timer">
                    <p id="day">{days}<span>DAYS</span></p>
                    <p  id="hour">{hours}<span>OURS</span></p>
                    <p id="minute">{minutes}<span>MINUTES</span></p>
                    <p id="second">{seconds}<span>SECONDS</span></p>
                </div>
            </section>
            <Footer />
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateSale);