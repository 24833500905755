import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactDOM from 'react-dom';


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import 'react-responsive-modal/styles.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { toFixed } from "../../store/numberFormat";
import { networkId, message } from "../../store/config";
import infocircle from "../../static/images/infocircle.svg";
import { DAOAddress, DAOContract, TokenContract, NFTContract } from '../../store/contract/index';
import { toggleLoader, getLottery, setAddress, getHBurnDAO, sendEmailHBurn, addHBurnProposal, getHBurnProposals } from "../../store/actions/Auth";

const DAOProposals = (props) => {


    let [dEnd, setdEnd] = useState("");
    let [balance, setBalance] = useState(0);
    let [daoData, setDaoData] = useState({});
    let [modalData, setModalData] = useState({});
    let [NFTbalance, setNFTBalance] = useState(0);
    let [HBurnbalance, setHBurnBalance] = useState(0);
    let [proposalInfo, setProposalInfo] = useState({});
    let [treasuryValue, setTreasuryValue] = useState("");
    let [proposalTitle, setproposalTitle] = useState("");
    let [executeModal, setExecuteModal] = useState(false);
    let [daoAddress, setDaoAddress] = useState(DAOAddress);
    let [proposalDetails, setproposalDetails] = useState([]);
    let [proposalDocument, setproposalDocument] = useState("");
    let [proposalInfoModal, setProposalInfoModal] = useState(false);
    let [proposalDescription, setproposalDescription] = useState("");
    let [createProposalModal, setCreateProposalModal] = useState(false);
    let [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleOnWheel);
        };
    }, []);

    useEffect(() => {
        // Update timestamp every second
        const intervalId = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
        }, 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        refreshBalance();
        props.getHBurnDAO({ daoAddress: daoAddress });
        props.getHBurnProposals({ daoAddress: daoAddress });
    }, []);

    useEffect(() => {
        if (props.publicAddress == null || props.publicAddress == undefined) {
            refreshBalance();
            return;
        } else {
            if (props.daoListHBurn && props.daoListHBurn.length > 0 && web3) {
                setDaoList();
            }
        }
    }, [props.daoListHBurn, props.selectDefaultNetwok, web3]);

    async function setDaoList() {
        const filtered = await Promise.all(
            props.daoListHBurn.filter(item => item['daoAddress'].toLowerCase() == daoAddress.toLowerCase()).map(async (item) => {
                return item;
            })
        );
        if (filtered.length > 0) {
            let stakeholders = filtered[0]['stakeholders'].map(item => item.toLowerCase());
            let onlyOwner = await DAOContract.methods.owner().call({ from: props.publicAddress });
            setDaoData({ ...filtered[0], stakeholders, onlyOwner });
        }
    }

    useEffect(() => {
        if (props.publicAddress && web3 && props.proposalListHBurn) {
            proposalsPopulate();
        } else {
            return;
        }
    }, [props.proposalListHBurn, props.publicAddress, web3]);

    async function proposalsPopulate() {
        let { proposalListHBurn } = props;
        let result1 = await DAOContract.methods.numProposals().call({ from: props.publicAddress });
        if (parseInt(result1) !== 0) {
            let array = [];
            for (let i = 0; i < result1; i++) {
                let result2 = await DAOContract.methods.proposals(i).call({ from: props.publicAddress });
                const updatedCollections = await Promise.all(
                    proposalListHBurn.filter(item => item['proposalTitle'].toLowerCase() == result2.title.toLowerCase()).map(async (item) => {
                        return { ...item, ...result2 };
                    })
                );
                array.push(updatedCollections[0]);
            }
            setproposalDetails(array);
        }
    }

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "proposalTitle") {
            setproposalTitle(e.target.value);
        } else if ([e.target.name] == "proposalDescription") {
            setproposalDescription(e.target.value);
        } else if ([e.target.name] == "proposalDocument") {
            setproposalDocument(e.target.files[0]);
        } else if ([e.target.name] == "dEnd") {
            setdEnd(e.target.value);
        } else if ([e.target.name] == "treasuryValue") {
            setTreasuryValue(parseFloat(e.target.value));
        }
    }

    async function refreshBalance() {
        if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");
        // check network
        let netId = await web3.eth.net.getId()
        if (parseInt(netId) != parseInt(networkId)) {
            return EventBus.publish('info', message);
        }
        let address = (await web3.currentProvider.enable())[0];
        props.setAddress(address);
        let balance = await web3.eth.getBalance(address); //Will give value in.
        let nftBalance = await NFTContract.methods.balanceOf(address).call({ from: address });
        setNFTBalance(nftBalance);
        let tokenBalance = await TokenContract.methods.balanceOf(address).call({ from: address });
        tokenBalance = web3.utils.fromWei(tokenBalance.toString(), "ether");
        balance = web3.utils.fromWei(balance.toString(), "ether");
        let precision = 3;
        let power = Math.pow(10, precision);
        balance = parseFloat(balance);
        balance = Math.trunc(balance * power) / power;
        setBalance(balance);
        tokenBalance = parseFloat(tokenBalance);
        tokenBalance = Math.trunc(tokenBalance * power) / power;
        setHBurnBalance(tokenBalance);
    }

    async function createProposal() {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            if (proposalTitle == "") {
                EventBus.publish("error", `Please enter proposal title`);
                return;
            }

            if (!proposalTitle.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter proposal title`);
                return;
            }

            if (!proposalTitle.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Proposal title must contain alphabets`);
                return;
            }

            if (proposalDescription == "") {
                EventBus.publish("error", `Please enter proposal description`);
                return;
            }

            if (!proposalDescription.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter proposal description`);
                return;
            }

            if (!proposalDescription.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Proposal description must contain alphabets`);
                return;
            }

            if (treasuryValue !== "") {
                if (parseFloat(treasuryValue) <= 0) {
                    EventBus.publish("error", `Please enter treasury value`);
                    return;
                }
            }

            if (proposalDocument == undefined) {
                EventBus.publish("error", `Please upload proposal document again or refresh the page`);
                return;
            }

            if (dEnd == "") {
                EventBus.publish("error", `Please enter proposal deadline`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            let deadline = parseInt((new Date(dEnd).getTime()) / 1000);

            if (balance == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.toggleLoader({
                message: "Adding Proposal...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: daoAddress,
                    data: DAOContract.methods
                        .createProposal(proposalTitle, proposalDescription, deadline)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {

                    const data = new FormData();
                    data.append("proposalDocument", proposalDocument);
                    data.append("proposalTitle", proposalTitle);
                    data.append("proposalDescription", proposalDescription);
                    data.append("deadline", deadline);
                    data.append("treasuryValue", treasuryValue);
                    data.append("daoAddress", daoAddress);
                    props.addHBurnProposal(data);

                    //         props.sendEmailHBurn({
                    //             daoAddress: daoAddress, emailSubject: `New Proposal Created in ${daoData['title']}`, proposalTitle: "Dear User",
                    //             proposalDescription: `A new proposal ${proposalTitle} has been created within ${daoData['title']}. Proposal details are as following:<br><br/>
                    // Title: ${proposalTitle}<br><br/>Description: ${proposalDescription}<br><br/>We encourage your active participation in the decision-making process.<br><br/>
                    // Please head to the DAO platform to review the details and cast your vote. Your input is instrumental in shaping the direction of DAO, and we value your engagement.` });

                    refreshBalance();
                    props.getHBurnDAO({ daoAddress: daoAddress });
                    props.getHBurnProposals({ daoAddress: daoAddress });
                    proposalsPopulate();
                    setDaoList();

                    EventBus.publish("success", `Proposal Created Successfully!`);
                    await waitFor(2500);
                    setdEnd("");
                    setproposalTitle("");
                    setTreasuryValue("");
                    setproposalDocument("");
                    setproposalDescription("");
                });
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Transfer Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Unable To add proposal`);
        }
    }

    async function vote(index) {
        try {

            let deployer = (await web3.currentProvider.enable())[0];

            if (balance == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let tokenBalance = await TokenContract.methods.balanceOf(deployer).call({ from: deployer });
            // let voteFee = web3.utils.toWei('1', 'ether');
            let voteFee = 1*10**8
            voteFee = voteFee.toString()
            let hasVoted = await DAOContract.methods.hasVoted(index, deployer).call();

            if (hasVoted == 1) {
                return EventBus.publish("error", `Already voted for this proposal`);
            }

            console.log("************************ tokenBalance",tokenBalance)
            console.log("************************ voteFee",voteFee)
            if (parseFloat(voteFee) > parseFloat(tokenBalance)) {
                return EventBus.publish("error", `Insufficient HBurn tokens for voting`);
            } else {
                props.toggleLoader({
                    message: "Approving Token...",
                    status: true,
                });
                await TokenContract.methods.approve(daoAddress, voteFee).send({ from: deployer }).then(console.log)
            }

            props.toggleLoader({
                message: "Voting...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: daoAddress,
                    data: DAOContract.methods
                        .voteOnProposal(index, voteFee, true)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.toggleLoader({ status: false });
                    proposalsPopulate();
                    EventBus.publish("success", `Voted Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Could not vote...",
                status: false,
            });
            EventBus.publish("error", `Could not vote`);
        }
    }

    async function against(index) {
        try {

            let deployer = (await web3.currentProvider.enable())[0];

            if (balance == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let tokenBalance = await TokenContract.methods.balanceOf(deployer).call({ from: deployer });
            // let voteFee = web3.utils.toWei('1', 'ether');
            let voteFee = 1*10**8
            voteFee = voteFee.toString()

            let hasVoted = await DAOContract.methods.hasVoted(index, deployer).call();

            if (hasVoted == 1) {
                return EventBus.publish("error", `Already voted for this proposal`);
            }

            if (parseFloat(voteFee) > parseFloat(tokenBalance)) {
                return EventBus.publish("error", `Insufficient HBurn tokens for voting`);
            } else {
                props.toggleLoader({
                    message: "Approving Token...",
                    status: true,
                });
                await TokenContract.methods.approve(daoAddress, voteFee).send({ from: deployer }).then(console.log)
            }

            props.toggleLoader({
                message: "Voting...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: daoAddress,
                    data: DAOContract.methods
                        .voteOnProposal(index, voteFee, false)
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.toggleLoader({ status: false });
                    proposalsPopulate();
                    EventBus.publish("success", `Voted Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Could not vote...",
                status: false,
            });
            EventBus.publish("error", `Could not vote`);
        }
    }

    async function execute(index) {
        try {

            await setDaoList();

            props.toggleLoader({
                message: "Loading...",
                status: true,
            });

            setModalData({ ...props.proposalListHBurn[index], ...proposalDetails[index] });

            props.toggleLoader({
                message: "Loading...",
                status: false,
            });

            setExecuteModal(true);

        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Loading...",
                status: false,
            });
            EventBus.publish("error", `Could not execute`);
        }
    }

    async function executeProposal() {
        try {

            let deployer = (await web3.currentProvider.enable())[0];

            if (balance == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.toggleLoader({
                message: "Executing...",
                status: true,
            });

            let index = parseInt(modalData['id']) - 1;

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: daoAddress,
                    data: DAOContract.methods
                        .executeProposal(index, "PASS")
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.toggleLoader({ status: false });
                    // props.sendEmailHBurn({
                    //     daoAddress: daoAddress, emailSubject: `Congratulations! Proposal Executed Successfully`, proposalTitle: "Dear User",
                    //     proposalDescription: `We're pleased to inform you that the recent proposal ${proposalObj['proposalTitle']} in your DAO has been successfully 
                    //     executed. Your involvement and contribution played a significant role in shaping the outcome.<br><br/>Thank you for actively participating in 
                    //     the governance of DAO. We appreciate your ongoing dedication to our community.` });
                    proposalsPopulate();
                    setExecuteModal(false);
                    EventBus.publish("success", `Executed Successfully!`);
                });
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Loading...",
                status: false,
            });
            EventBus.publish("error", `Could not execute`);
        }
    }

    async function rejectProposal() {
        try {

            let deployer = (await web3.currentProvider.enable())[0];

            if (balance == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            props.toggleLoader({
                message: "Transfering Funds...",
                status: true,
            });

            props.toggleLoader({
                message: "Executing...",
                status: true,
            });

            let index = parseInt(modalData['id']) - 1;

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: daoAddress,
                    data: DAOContract.methods
                        .executeProposal(index, "FAIL")
                        .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                    props.toggleLoader({ status: false });
                    proposalsPopulate();
                    setExecuteModal(false);
                    EventBus.publish("success", `Executed Successfully!`);
                });

        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Loading...",
                status: false,
            });
            EventBus.publish("error", `Could not execute`);
        }
    }

    async function callFilter(value) {
        let filterValue = props.proposalListHBurn.filter((a) => {
            if (value) {
                return a.proposalTitle?.toString().includes(value)
            }
            else {
                return a
            }
        });
        setproposalDetails(filterValue);
    }

    async function proposalInformation(item) {
        setProposalInfo(item);
        setProposalInfoModal(true);
    }

    // console.log("*** daoListHBurn :: ", props.daoListHBurn);
    // console.log("*** balance :: ", balance);
    // console.log("*** HBurnbalance :: ", HBurnbalance);
    // console.log("*** proposalListHBurn :: ", props.proposalListHBurn);
    console.log("*** proposalDetails :: ", proposalDetails);
    // console.log("*** proposalDetails :: ", proposalDetails[0].voteStruct.communityYes);

    // console.log("*** currentTime :: ", currentTime);
    // console.log("*** proposalDetails :: ", proposalDetails);
    // console.log("*** daoData :: ", daoData);
    // console.log("*** NFTbalance :: ", NFTbalance);
    // console.log("*** proposalInfo :: ", proposalInfo);

    return (
        <div className="wrapper">
            <Navbar />

            <section className="dao-wrapper">
                <div className="container">
                    <div className="head">
                        <h1>DAO Proposals</h1>
                    </div>

                    <div className="filter-wrap">
                        <div className="form-group search-wrap">
                            <input type="search" placeholder="Search proposal by title" onChange={e => callFilter(e.target.value)} />
                            <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/search.png" alt="" />
                        </div>

                        <div className="treasury-value">
                            <label>Treasury value</label>
                            <span>100,000 HBurn</span>
                        </div>

                        <button onClick={() => setCreateProposalModal(true)} className="common-btn ">Create a Proposal<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/create-propsoal.png" alt="" /></button>
                    </div>

                    <span style={{ color: "white" }}>***Note: Community votes will only take affect when atleast 10 community votes have been casted</span>
                    <br />
                    <br />

                    <div className="proposals-table">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Title</th>
                                    <th>Expiry Date</th>
                                    <th>Status</th>
                                    <th>Vote Count</th>
                                    <th>Vote/Reject</th>
                                    {
                                        Object.keys(daoData).length > 0 && (NFTbalance > 0 || (daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()))) &&
                                        <th>Action</th>
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    proposalDetails?.length > 0 &&
                                    proposalDetails?.map((item, index) => (
                                        <tr>
                                            <td><img onClick={() => proposalInformation(item)} className="info-edit" src={infocircle} alt="" /></td>
                                            <td>{item?.title ? item?.title : item?.proposalTitle ? item?.proposalTitle : "N/A"}</td>
                                            <td>{item?.deadline ? moment(Number(item.deadline) * 1000).format('L hh:mm:ss A') : "N/A"}</td>
                                            {
                                                (currentTime) < parseInt(item?.deadline)
                                                    ?
                                                    <td className="active">Active</td>
                                                    :
                                                    <td className="">Expired</td>
                                            }

                                            <td className="vote-count">
                                                <span>
                                                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/voted-new.png" alt="" />
                                                    {
                                                        parseInt(item?.voteCount) > 0 ?
                                                            parseInt(item?.yayVotes) > 0 ?
                                                                // parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo) > 9 ?
                                                                (
                                                                    (toFixed((((parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo)) == 0 ? 0 : ((parseInt(item?.voteStruct.teamYes) / (parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo))) * 20)) + ((parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo) == 0) ? 0 : ((parseInt(item?.voteStruct.partnerYes) / (parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo))) * 20)) + ((parseInt(item?.voteStruct.communityYes) + parseInt(item?.voteStruct.communityNo)) == 0 ? 0 : ((parseInt(item?.voteStruct.communityYes) / (parseInt(item?.voteStruct.communityYes) + parseInt(item?.voteStruct.communityNo))) * 60))), 'gas'))
                                                                )
                                                                // :
                                                                // (
                                                                //     (toFixed((((parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo)) == 0 ? 0 : ((parseInt(item?.voteStruct.teamYes) / (parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo))) * 20)) + ((parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo) == 0) ? 0 : ((parseInt(item?.voteStruct.partnerYes) / (parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo))) * 20))), 'gas'))
                                                                // )
                                                                :
                                                                0
                                                            :
                                                            0
                                                    }% voted
                                                </span>
                                                <span>
                                                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/rejected-new.png" alt="" />
                                                    {
                                                        parseInt(item?.voteCount) > 0 ?
                                                            parseInt(item?.nayVotes) > 0 ?
                                                                // parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo) > 9 ?
                                                                (
                                                                    (toFixed((((parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo)) == 0 ? 0 : ((parseInt(item?.voteStruct.teamNo) / (parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo))) * 20)) + ((parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo) == 0) ? 0 : ((parseInt(item?.voteStruct.partnerNo) / (parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo))) * 20)) + ((parseInt(item?.voteStruct.communityYes) + parseInt(item?.voteStruct.communityNo)) == 0 ? 0 : ((parseInt(item?.voteStruct.communityNo) / (parseInt(item?.voteStruct.communityYes) + parseInt(item?.voteStruct.communityNo))) * 60))), 'gas'))
                                                                )
                                                                // :
                                                                // (
                                                                //     (toFixed((((parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo)) == 0 ? 0 : ((parseInt(item?.voteStruct.teamNo) / (parseInt(item?.voteStruct.teamYes) + parseInt(item?.voteStruct.teamNo))) * 20)) + ((parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo) == 0) ? 0 : ((parseInt(item?.voteStruct.partnerNo) / (parseInt(item?.voteStruct.partnerYes) + parseInt(item?.voteStruct.partnerNo))) * 20))), 'gas'))
                                                                // )
                                                                :
                                                                0
                                                            :
                                                            0
                                                    }% rejected
                                                </span>
                                            </td>
                                            <td className="vote-reject">
                                                {
                                                    (currentTime) < parseInt(item?.deadline) ?
                                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/vote.png" alt="" onClick={() => vote(index)} /> :
                                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/vote.png" alt="" />
                                                }

                                                {
                                                    (currentTime) < parseInt(item?.deadline) ?
                                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/reject.png" alt="" onClick={() => against(index)} /> :
                                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/reject.png" alt="" />
                                                }
                                            </td>
                                            {
                                                (currentTime) > parseInt(item?.deadline) && Object.keys(daoData).length > 0 && (NFTbalance > 0 || (daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()))) ?
                                                    <td className="action">
                                                        <button onClick={() => execute(index)} className="common-btn">Execute <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/execute.png" alt="" /></button>
                                                    </td> :
                                                    <></>
                                            }
                                            <td>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <Footer />

            <Modal
                open={createProposalModal}
                onClose={() => setCreateProposalModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setCreateProposalModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>New Proposal</h2>

                    <div className="form-group">
                        <input
                            type="text"
                            name='proposalTitle'
                            value={proposalTitle}
                            onChange={handleOnInput}
                            placeholder="Proposal Title*"
                        />
                    </div>

                    <div className="form-group textarea">
                        <textarea
                            as="textarea"
                            rows={6}
                            name='proposalDescription'
                            value={proposalDescription}
                            onChange={handleOnInput}
                            placeholder="Proposal detail"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="number"
                            name='treasuryValue'
                            value={treasuryValue}
                            onChange={handleOnInput}
                            placeholder="Treasury Value*"
                        />
                    </div>

                    <div className="form-group filewrap">
                        <div className="file-inner">
                            <span>{proposalDocument ? (proposalDocument['name'] && proposalDocument['name'].substring(0, 10) + '...') : "Proposal document"}</span>
                            <div className="upload-btn common-btn">Upload PDF File</div>
                        </div>

                        <input
                            type="file"
                            name='proposalDocument'
                            placeholder={proposalDocument ? (proposalDocument['name'] && proposalDocument['name'].substring(0, 10) + '...') : "Upload PDF File*"}
                            accept=".pdf"
                            onChange={handleOnInput}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="datetime-local"
                            placeholder="End date"
                            name="dEnd"
                            value={dEnd}
                            onInput={handleOnInput}
                        />
                    </div>

                    <button className="common-btn pr" onClick={() => createProposal()}>submit Proposal<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/submit.png" alt="" /></button>
                </div>
            </Modal>

            <Modal
                open={executeModal}
                onClose={() => setExecuteModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setExecuteModal(false)}
                    />
                </button>

                <div className="modal-body">
                    {
                        Object.keys(modalData).length > 0 &&
                        <>
                            <h2 className="text-center mb-5">{modalData?.['title']}</h2>
                            <p className="text-center mb-2">{modalData?.['description']}</p>
                            <form>
                                {
                                    modalData['executed'] == false ?
                                        parseInt(modalData['yayVotes']) !== 0 || parseInt(modalData['nayVotes']) !== 0 ?
                                            NFTbalance > 0 || daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) &&
                                                parseInt(modalData?.voteStruct.communityYes) + parseInt(modalData?.voteStruct.communityNo) > 9 ?
                                                (
                                                    (toFixed((((parseInt(modalData?.voteStruct.teamYes) + parseInt(modalData?.voteStruct.teamNo)) == 0 ? 0 : ((parseInt(modalData?.voteStruct.teamYes) / (parseInt(modalData?.voteStruct.teamYes) + parseInt(modalData?.voteStruct.teamNo))) * 20)) + ((parseInt(modalData?.voteStruct.partnerYes) + parseInt(modalData?.voteStruct.partnerNo) == 0) ? 0 : ((parseInt(modalData?.voteStruct.partnerYes) / (parseInt(modalData?.voteStruct.partnerYes) + parseInt(modalData?.voteStruct.partnerNo))) * 20)) + ((parseInt(modalData?.voteStruct.communityYes) + parseInt(modalData?.voteStruct.communityNo)) == 0 ? 0 : ((parseInt(modalData?.voteStruct.communityYes) / (parseInt(modalData?.voteStruct.communityYes) + parseInt(modalData?.voteStruct.communityNo))) * 60))), 'gas'))
                                                ) >= 50 ?
                                                    <button className="common-btn pr" onClick={(event) => {
                                                        event.preventDefault();
                                                        executeProposal();
                                                    }}>Execute proposal<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/submit.png" alt="" /></button>
                                                    :
                                                    <button className="common-btn pr" onClick={(event) => {
                                                        event.preventDefault();
                                                        rejectProposal();
                                                    }}>Reject proposal<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/submit.png" alt="" /></button>
                                                : <button className="common-btn pr" onClick={(event) => {
                                                    event.preventDefault();
                                                    rejectProposal();
                                                }}>Reject proposal<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/submit.png" alt="" /></button>
                                            :
                                            <>
                                                {
                                                    daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) && modalData['executed'] == false &&
                                                    <button className="common-btn pr" onClick={(event) => {
                                                        event.preventDefault();
                                                        rejectProposal();
                                                    }}>Conclude proposal<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/submit.png" alt="" /></button>
                                                }
                                                <br />
                                                <br />
                                                <p className="note" disabled>
                                                    No votes submitted
                                                </p>
                                            </>
                                        :
                                        <p className="note" disabled>
                                            Decision Made
                                        </p>
                                }
                            </form>
                        </>
                    }
                </div>
            </Modal>

            <Modal
                open={proposalInfoModal}
                onClose={() => setProposalInfoModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setProposalInfoModal(false)}
                    />
                </button>

                <div className="modal-body">

                    <h2>{proposalInfo && proposalInfo['proposalTitle'] ? proposalInfo['proposalTitle'] : "N/A"}</h2>
                    <p>{proposalInfo && proposalInfo['proposalDescription'] ? proposalInfo['proposalDescription'] : "N/A"}</p>
                    <p>{proposalInfo && proposalInfo['treasuryValue'] ? `${proposalInfo['treasuryValue']} Tokens` : "N/A"}</p>
                    {
                        proposalInfo && proposalInfo['proposalDocument'] && proposalInfo['proposalDocument'] !== "" &&
                        <p>
                            <a href={proposalInfo['proposalDocument']} target="_blank">View Document</a>
                        </p>
                    }
                    <span>{proposalInfo && proposalInfo['deadline'] ? moment(Number(proposalInfo.deadline) * 1000).format('L hh:mm:ss A') : "N/A"}</span>
                </div>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    getLottery,
    setAddress,
    toggleLoader,
    getHBurnDAO,
    sendEmailHBurn,
    addHBurnProposal,
    getHBurnProposals,
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, daoListHBurn, proposalListHBurn } = Auth;
    return { publicAddress, daoListHBurn, proposalListHBurn }
};

export default connect(mapStateToProps, mapDispatchToProps)(DAOProposals);