import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { web3 } from '../../store/web3';
import '../../app/HBurnMarketplace/index.css';
import { networkId, message } from '../../store/config';
import { login, logout, setAddress } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            token: '',
            errorText: ''
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.setAddress(address);
            this.props.login(address);
            this.setState({ address });
        });
    };

    logout() {
        // Delete all items from local storage to logout the user
        localStorage.clear();
        this.props.logout('');
        this.setState({ token: '', address: '' });
    }

    render() {
        let { publicAddress, isLogin } = this.props;
        return (
            <header>
                <div class="container">
                    <nav class="navbar">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>

                            <li>
                                <a href="/#aim">Aim</a>
                            </li>

                            <li>
                                {/* <Link to="/marketplace">Marketplace</Link> */}
                                <a href="https://darkpaper.hburn.co" target="_blank">Darkpaper</a>
                            </li>
                            {/* {isLogin &&
                                <li>
                                    <Link to="/profile">Profile</Link>
                                </li>
                            } */}
                        </ul>

                        <div className="logo-wrap">
                                    <Link to="/" class="logo" >
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn96.png" alt="" />
                                    </Link>
                        </div>

                        <ul>
                            <li>
                                <a href="/#roadmap">Roadmap</a>
                            </li>

                            <li>
                                <a href="/#tokenomics">Tokenomics</a>
                            </li>

                            <li>
                                <Link to="/seed-round">Seed Round</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}
const mapDispatchToProps = {
    login,
    logout,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, isLogin} = Auth;
    return { publicAddress, isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);