import EventBus from "eventing-bus";
import axios from 'axios';
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import Countdown,{zeroPad} from 'react-countdown';
import { CopyToClipboard } from "react-copy-to-clipboard";

import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { networkId, message } from "../../store/config";
import { toggleLoader,updateUser, updateUserImage,getUser  } from "../../store/actions/Auth";
import {SeedRountAddress,SeedRountContract,USDTAddress,USDTContract } from '../../store/contract/index';
import ProgressBar from "@ramonak/react-progress-bar";


const EditProfilr = (props) => {

    const [preview, setPreview] = useState(props?.userData?.image);
    const [name, setname] = useState(props?.userData?.name);
    const [desc, setdesc] = useState(props?.userData?.desc);
    const [facebook, setfacebook] = useState(props?.userData?.facebook);
    const [discord, setdiscord] = useState(props?.userData?.discord);
    const [twitter, settwitter] = useState(props?.userData?.twitter);


    const submitUser = () => {
        try {
            if (!name) return EventBus.publish('error', `Please add name`);
            if (!name.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter name`);
                return;
            }
            if (!name.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Name must contain alphabets`);
                return;
            }
            if (!desc) return EventBus.publish('error', `Please add description`);
            if (!desc.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter description`);
                return;
            }
            if (!desc.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Description must contain alphabets`);
                return;
            }
            if(facebook){
                if (!facebook.split("https://")[1].replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter facebook address`);
                    return;
                }
                if (!facebook.split("https://")[1].match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Facebook must contain alphabets`);
                    return;
                }
            }
            if(discord){
                if (!discord.split("https://")[1].replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter discord address`);
                    return;
                }
                if (!discord.split("https://")[1].match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Discord must contain alphabets`);
                    return;
                }
            }
            if(twitter){
                if (!twitter.split("https://")[1].replace(/\s/g, '').length) {
                    EventBus.publish("error", `Please enter twitter address`);
                    return;
                }
                if (!twitter.split("https://")[1].match(/[a-zA-Z]/)) {
                    EventBus.publish("error", `Twitter must contain alphabets`);
                    return;
                }
            }
            props.toggleLoader({message:"User profile updating...", status:true});
            props.updateUser({name,desc,facebook,discord,twitter});
        } catch (error) {
            return EventBus.publish("error", error);
        }
    }

    const uploadImage = async (e) => {
        try {
            if(e.target.files.length == 0) return;
            let files = e.target.files[0].type;
            if (files.split('/')[0] !== 'image') return EventBus.publish('error', `Please upload image only in jpeg, png, gif`);
            let reader = new FileReader();
            reader.onload = async (event) => {
                    setPreview(event.target.result);
                };
            reader.readAsDataURL(e.target.files[0]);
            props.toggleLoader({message:"Update profile image...", status:true});
            let Userfiles = e.target.files[0];
            let formData = new FormData();
            formData.append('user', e.target.files[0]);
            props.updateUserImage(formData);
        } catch (error) {
            return EventBus.publish("error", error);
        }
    };

    useEffect(()=>{
        props.getUser();
    },[]);

    useEffect(()=>{
        if(Object.keys(props.userData).length > 0){
            setPreview(props.userData.image);
            setname(props.userData.name);
            setdesc(props.userData.desc);
            setfacebook(props.userData.facebook);
            setdiscord(props.userData.discord);
            settwitter(props.userData.twitter);
        }
    },[props.userData]);

    // useEffect(()=>{
    //     if(!props.isLogin) return navigate("/");
    // },[props.isLogin])

    return (
        <div className="wrapper">
            <Navbar />
            
            <section className="edit-profile">
                <div className="container">
                    <div className="head">
                        <span>Lorem Ipsum</span>

                        <h1>Edit Profile</h1>
                    </div>

                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>

                    <div className="inner">
                        <div className="img-wrap">
                            <img src={preview ? preview : "https://ox-burn-assets.s3.us-east-2.amazonaws.com/banner-bg.png"} alt="" />
                            <img className="edit-icon" src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/edit.png" alt="" />
                            <input type="file"  onChange={(e)=>uploadImage(e)} accept="image/*"/>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Name"
                                onChange={e=>setname(e.target.value)} 
                                value={name}
                            />
                        </div>

                        <div className="form-group textarea">
                            <textarea
                                as="textarea"
                                rows={6}
                                placeholder="Description"
                                onChange={e=>setdesc(e.target.value)} 
                                value={desc}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                placeholder="Facebook Link" onChange={e=>{
                                    let inputValue = e.target.value;
                                    if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                                      inputValue = 'https://' + inputValue;
                                      setfacebook(inputValue) 
                                    }else{
                                      setfacebook(e.target.value) 
                                    }
                            }} value={facebook}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                placeholder="Discord Link" onChange={e=>{
                                    let inputValue = e.target.value;
                                    if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                                      inputValue = 'https://' + inputValue;
                                      setdiscord(inputValue) 
                                    }else{
                                      setdiscord(e.target.value) 
                                    }
                            }} value={discord}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="url"
                                placeholder="Twitter Link" onChange={e=>{
                                    let inputValue = e.target.value;
                                    if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                                      inputValue = 'https://' + inputValue;
                                      settwitter(inputValue) 
                                    }else{
                                      settwitter(e.target.value) 
                                    }
                            }} value={twitter}
                            />
                        </div>


                        <button className="common-btn"  onClick={submitUser}>SAVE CHANGES <img className="edit-icon" src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/save.png" alt="" /></button>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

const mapDispatchToProps = { toggleLoader,updateUser,updateUserImage,getUser };

const mapStateToProps = ({ Auth }) => {
  let { isLogin, userData} = Auth;
  return { isLogin, userData }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilr);