import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
// import {  } from '../../store/contract/index';
import { toggleLoader, getAllCollection, getExplore, addRemoveFavItems, getCategory } from "../../store/actions/Auth";
const alternate = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png";

const Explore = (props) => {
    
    const [status, setStatus] = useState();
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);
    const [setFound, setNotFound] = useState(false);

    const [items, setItems] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        status: [],
        categories: [],
        collection: [],
    });

    // Function to handle checkbox change and filter items
    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [name]: checked
                ? [...prevOptions[name], value]
                : prevOptions[name].filter((item) => item !== value),
        }));
    };

    useEffect(() => {
        props.toggleLoader({message:"Load explore...",status:true});
        props.getAllCollection({ chain: props.chain });
        props.getExplore({ chain: props.chain });
        props.getCategory();
    }, [props.chain]);

    useEffect(() => {
        if (props.explore.length > 0) setItems(props.explore);
    }, [props.explore]);

    const applyFilters = () => {
        const filtered = props.explore.filter((item) => {
            return (
                (!filterOptions.status.length ||
                    filterOptions.status.includes(item.status)) &&
                (!filterOptions.categories.length ||
                    filterOptions.categories.includes(item.collectionType)) &&
                (!filterOptions.collection.length ||
                    filterOptions.collection.includes(item.collections._id))
            );
        });
        if (filtered.length == 0) {
            setNext(0)
            setNotFound(true);
        }
        else {
            setNotFound(false);
            setNext(itemPerRow);
        }
        setItems(filtered);
    };

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    const AddFavItems = (nftId) => {
        props.addRemoveFavItems({ nftId })
    }

    const handleImageError = (idx) => {
        items[idx]['image'] = alternate;
        setItems([...items]);
    };

    const handleUserImageError = (idx) => {
        items[idx]['users']['image'] = alternate;
        setItems([...items]);
    };

    return (
        <div className="wrapper">
            <Navbar />

            <section className="explore vh">
                {/* <h2 class="text-center">Coming Soon</h2> */}
                <div className="container">
                    <div className="head text-center">
                        <h1>explore</h1>
                    </div>
                    <div className="row justify-content-center">
                    {
                        !setFound ?
                            items && items.length > 0 && items.filter(item=>item.isMarketItem == true).slice(0, next).map((item, idx) =>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <Link to={`/buy-nft/${item['_id']}`} className="card-box">
                                <div className="card-inner">
                                <img key={idx} src={item?.['image'] ? item?.['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                                <h3>{item['title'] ? item['title'] : "-"}</h3>
                                    <div class="card-body">
                                        <Link to={`/collection/${item['chain']}/${item['tokenAddress']}`} className="card-left">
                                            <img key={idx} src={item?.['collections']?.['profileImage'] ? item?.['collections']?.['profileImage'] : alternate} onError={() => handleUserImageError(idx)} alt="Alternate Image" />
                                            <div className="card-right-text">
                                                <span>Collection</span>
                                                <h4>{(item?.['collections']?.['collectionName']) ? (item?.['collections']?.['collectionName']) : (item?.['users']?.['tokenAddress'].slice(0, 6) + "..." + item?.['users']?.['publicAddress'].slice(38, 42))}</h4>
                                            </div>
                                        </Link>
                                        <div className="card-right">
                                            <span>{item.isRequiredHBurn ? " HBurn" :" HBAR"}</span>
                                        </div>
                                    </div>
                                </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">{item?.price} {item.isRequiredHBurn ? " HBurn" :" HBAR"}</span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            {item?.tokenType == "erc1155" 
                                                ?
                                                <a href="#">Mint Amount  {item?.mintAmount}</a>
                                                :
                                                <a href="#"> Token Id {item?.nftId}</a>
                                            }
                                        </div>
                                    </div>
                            </Link>
                        </div>
                          ) :
                          <div className="text-center">
                              <h2> Not Found </h2>
                          </div>
                  }
                    </div>
                    {next < items?.filter(item=>item.isMarketItem == true)?.length && (
                          <a className="common-btn" onClick={handleMoreImage}> Load More <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></a>
                    )}
                </div>
            </section>
            
            <Footer />
        </div>
    );
}

const mapDispatchToProps = { toggleLoader, getAllCollection, getExplore, addRemoveFavItems, getCategory };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollectionDetails, collections, explore, category } = Auth;
    return { isLogin, chain, publicAddress, singleCollectionDetails, collections, explore, category }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explore);