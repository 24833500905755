import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { NavLink, Link, useParams } from "react-router-dom";
import moment from 'moment';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import {} from '../../store/contract/index';
import { toggleLoader, getCollection, getUser, getUserNft, getUserFavItems, addRemoveFavItems  } from "../../store/actions/Auth";

const alternate = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png";

const Profile = (props) => {

    let [user, setUser] = useState({});
    let [userNftHolding, setuserNftHolding] = useState([]);
    let [userFavHolding, setuserFavHolding] = useState([]);
    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);

    useEffect(() => {
        if(props.isLogin == true){
            props.toggleLoader({status:true,message:"Load profile"});
            props.getCollection();
            props.getUserNft();
            props.getUser();
            props.getUserFavItems();
        }
    }, [props.isLogin]);

    useEffect(() => {
        if (Object.keys(props.userData).length > 0) setUser(props.userData);
    }, [props.userData]);

    async function copiedAddress() {
        EventBus.publish("success", "Owner Address Copied");
    }

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    useEffect(() => {
        if (props.userNfts.length > 0 && props.isLogin ==  true) {
            let userNFTS = props.userNfts.filter(item=>{
                if(item.tokenType == "erc1155"){
                    return item.mintAmount !== 0;
                }else{
                    return item;
                }
            })
            console.log("****************** props.userNfts",props.userNfts)
            setuserNftHolding(props.userNfts);
        }
    }, [props.userNfts, props.isLogin]);

    const handleNftHoldingImageError = (idx) => {
        userNftHolding[idx]['image'] = alternate;
        setuserNftHolding([...userNftHolding]);
    };

    useEffect(() => {
        if (props.userFavitems && props.isLogin == true) setuserFavHolding(props.userFavitems)
    }, [props.userFavitems, props.isLogin])


    const handleNftFavImageError = (idx) => {
        userFavHolding[idx]['nft']['image'] = alternate;
        setuserFavHolding([...userFavHolding]);
    }

    const AddFavItems = (nftId, tokenAddress, chain) => {
        props.addRemoveFavItems({ nftId, tokenAddress, chain });
    }

    const handleImageError = (idx) => {
        nfts[idx]['image'] = alternate;
        setNfts([...nfts]);
    };

    const copyAddress =()=> EventBus.publish('success',`address copy!`);
    
    return (
        <div className="wrapper">
            <Navbar />

            <section className="profile">
                <div className="container">
                    <div className="inner">
                        <div className="img-wrap">
                            <img src={user?.image ? user['image'] : alternate} alt="..." />
                        </div>

                        <div className="content">
                            <div className="upper">
                                <div >
                                    <h3>{user?.name ? user['name'] : ""}</h3>

                                    <p>{user?.desc ? user['desc'] : ""}</p>
                                </div>

                                <ul>
                                    {/* <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-facebook.png" alt="..." /></a></li>
                                    <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-twitter.png" alt="..." /></a></li>
                                    <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-google.png" alt="..." /></a></li>
                                    <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-chat.png" alt="..." /></a></li> */}

                                                <li>
                                                    {user?.facebook &&
                                                        <a href={user['facebook']} target='_blank'>
                                                             <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-facebook.png"} alt="" />
                                                        </a>
                                                    }
                                                </li>

                                                {/* <li>

                                                {collection && collection['discord'] && collection['discord'] !== "undefined" &&
                                                    <a href={collection['discord']}>
                                                        <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-discord.png"}  alt="" />
                                                    </a>
                                                }

                                                </li> */}

                                                <li>
                                                    {user?.twitter &&
                                                        <a href={user['twitter']} target='_blank'>
                                                            <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-twitter.png"} alt="" />
                                                        </a>
                                                    }
                                                </li>

                                                <li>
                                                    {user?.discord &&
                                                        <a href={user['discord']} target='_blank'>
                                                            <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-discord.png"}  alt="" />
                                                        </a>
                                                    }
                                                </li>

                                </ul>
                                
                            </div>

                            <div className="bottom">
                                {user && user.publicAddress &&
                                    <CopyToClipboard className="address" onCopy={copyAddress}>
                                        <span>{user?.publicAddress.slice(0,10)+'...'+user?.publicAddress.slice(36,user?.publicAddress.length)} <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/document-copy.png" alt="..." /></span>
                                    </CopyToClipboard>
                                }
                                {user && user.publicAddress &&
                                <NavLink to={"/edit-profile"} className="common-btn border-white">
                                                Edit Profile <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-edit.png" alt="" />
                                </NavLink>
                                }
                                {/* <a className="common-btn" href="">EDIT PROFILE <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-edit.png" alt="" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="explore pr">
                <div className="container">
                    <div className="head text-center">
                        <h2>Owned NFT's</h2>
                    </div>

                    <div className="row">
                    {userNftHolding.length > 0 && userNftHolding && userNftHolding.slice(0, next).map((item, idx) =>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <Link to={`/buy-nft/${item['_id']}`} className="card-box">
                                <div className="card-inner">
                                <img key={idx} src={item?.['image'] ? item?.['image'] : alternate} onError={() => handleImageError(idx)} alt="..." />

                                <h3>{item['title'] ? item['title'] : "-"}</h3>
                                    {/* <div class="card-body">
                                        <div className="card-left">
                                            <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/avatar.png" alt="" />
                                            <div className="card-right-text">
                                                <span>Creator</span>
                                                <h4>SalvadorDali</h4>
                                            </div>
                                        </div>
                                        <div className="card-right">
                                            <span>HBAR</span>
                                        </div>
                                    </div> */}
                                </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">{item?.price} {item?.isRequiredHBurn ? " HBurn " : " HBAR "} </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                          <a href=""> Token Id {item?.nftId} </a>
                                        </div>
                                    </div>
                            </Link>
                        </div>
                     )}
                    </div>
                    {next < userNftHolding?.length && (
                          <a className="common-btn" onClick={handleMoreImage}> Load More <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></a>
                    )}
                    {/* <a className="common-btn" href="">Load More <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></a> */}
                </div>
            </section>
            <Footer />
        </div>
    );
}

const mapDispatchToProps = { toggleLoader, getCollection, getUser, getUserNft, getUserFavItems, addRemoveFavItems };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, userCollections, userData, userNfts, userFavitems } = Auth;
    console.log("********************* userNfts",userNfts)
    return { isLogin, chain, publicAddress, userCollections, userData, userNfts, userFavitems }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);