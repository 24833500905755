/* -- set app title --*/
const AppTitle = 'Hburn Frontend';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['production'];

/* -- set API URLs --*/
const testing = 'https://dev.server.hburn.co';
const production = 'https://www.prod.server.hburn.co';
// const development = 'http://localhost:4000';
const development = 'https://dev.server.hburn.co';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '', networkIdEth = '', networkType ='';

switch (AppMode[0]) {
  case 'development':
    networkId = 296;
    networkIdEth = 11155111;
    networkType = true;
    SocketUrl = development;
    explorer = 'https://hashscan.io/testnet'
    message = 'Please switch your network to Hedera Testnet';
    break;
  case 'production':
    networkId = 295;
    networkIdEth = 1;
    networkType = false;
    SocketUrl = production;
    message = 'Please switch your network to Hedera Mainnet';
    explorer = 'https://hashscan.io/mainnet'
    break;
  case 'testing':
    networkId = 296;
    SocketUrl = testing;
    networkType = true;
    explorer = 'https://hashscan.io/testnet'
    message = 'Please switch your network to Hedera Testnet';
    break;
  default:
    networkId = 296;
    networkIdEth = 11155111;
    networkType = true;
    SocketUrl = 'http://localhost:4000';
    explorer = 'https://hashscan.io/testnet'
    message = 'Please switch your network to Hedera Testnet';
}

let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer,networkIdEth, env, networkType };