import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import 'react-responsive-modal/styles.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { toFixed } from "../../store/numberFormat";
import { networkId, message } from "../../store/config";
import UnknownLogo from "../../static/images/bookmark.png";
import { toggleLoader, setAddress } from "../../store/actions/Auth";
import { ERC20ABI, HBurnFactoryContract, HBurnPairABI, HBurnRouterAddress, HBurnRouterContract, TokenContract } from '../../store/contract/index';


const Swap = (props) => {

    let [selectToken, setSelectToken] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [addliquidityModal, setAddLiquidityModal] = useState(false);
    const [removeliquidityModal, setRemoveLiquidityModal] = useState(false);

    const [count, setCount] = useState(false); // Start from 0

    const [swapModal1, setSwapModal1] = useState(false);
    const [swapModal2, setSwapModal2] = useState(false);
    const [poolModal1, setPoolModal1] = useState(false);
    const [poolModal2, setPoolModal2] = useState(false);
    const [swapValue1, setSwapValue1] = useState("HBAR");
    const [swapValue2, setSwapValue2] = useState("HBAR");
    const [poolValue1, setPoolValue1] = useState("HBAR");
    const [poolValue2, setPoolValue2] = useState("HBAR");

    const [slippageValue, setSlippageValue] = useState(0.3);
    const [iDeadlineValue, setIDeadlineValue] = useState(30);
    const [deadlineValue, setDeadlineValue] = useState(moment(Date.now()).add(30, 'm').toDate());

    let [selectSwapToken1, setSelectSwapToken1] = useState("");
    let [selectSwapToken2, setSelectSwapToken2] = useState("");

    let [selectPoolToken1, setSelectPoolToken1] = useState("");
    let [selectPoolToken2, setSelectPoolToken2] = useState("");

    const [txHash, setTxHash] = useState("");

    let [walletBalance, setWalletBalance] = useState("");
    let [OxBurnBalance, setOxBurnBalance] = useState("");
    let [primaryToken, setPrimaryToken] = useState("");
    let [secondaryToken, setSecondaryToken] = useState("");
    let [primaryAmount, setPrimaryAmount] = useState("");
    let [secondaryAmount, setSecondaryAmount] = useState("");
    let [primaryAmountP, setPrimaryAmountP] = useState("");
    let [liquidityAmount, setLiquidityAmount] = useState("");
    let [liquidityAmountActual, setLiquidityAmountActual] = useState(0);
    let [secondaryAmountP, setSecondaryAmountP] = useState("");
    let [primaryAmountPMin, setPrimaryAmountPMin] = useState("");
    let [secondaryAmountPMin, setSecondaryAmountPMin] = useState("");
    let [pSymbol, setPSymbol] = useState("");
    let [sSymbol, setSSymbol] = useState("");
    let [token, setToken] = useState("HBAR");
    let [wToken, setWToken] = useState("WHBAR");

    // START Query for all available pairs  
    const [pairs, setPairs] = useState([]);
    // Storing sepparate tokens on an array
    const [tokenList, setTokenList] = useState([]);
    const [tokenList1, setTokenList1] = useState([]);

    const uniqueToken0Symbols = new Set(); // Helper to keep track of unique symbols
    const uniqueToken1Symbols = new Set(); // Helper to keep track of unique symbols

    const getAllUniqueTokens = (tokens) => {
        const tokenMap = new Map();

        tokens.forEach(token => {
            // If the map already has the token address, we skip it, otherwise we add it
            if (!tokenMap.has(token.address)) {
                tokenMap.set(token.address, token);
            }
        });
        // Convert the map values back to an array
        return Array.from(tokenMap.values());
    };

    const uniqueTokens = getAllUniqueTokens(tokenList);

    // Filter pairs based on the search term and removes repeated tokens
    const filteredPairs = tokenList
        .filter(pair => {
            const includesSearchTerm = pair.symbol.includes(primaryToken);
            const isUnique = !uniqueToken0Symbols.has(pair.symbol);
            if (includesSearchTerm && isUnique) {
                // console.log("PAIR= " + pair.symbol)
                uniqueToken0Symbols.add(pair.symbol);
                return true;
            }
            return false;
        });

    // Filter pairs based on the search term and removes repeated tokens
    const filteredPairs2 = tokenList1
        .filter(pair => {
            const includesSearchTerm = pair.symbol.includes(secondaryToken);
            const isUnique = !uniqueToken1Symbols.has(pair.symbol);
            if (includesSearchTerm && isUnique) {
                // console.log("PAIR= " + pair.symbol)
                uniqueToken1Symbols.add(pair.symbol);
                return true;
            }
            return false;
        });

    async function getPairs() {

        // Create a contract instance
        // const factoryContract = new web3.eth.Contract(HBurnFactoryContractABI, HBurnFactoryContractAddress);

        try {
            // Get the total number of pairs from the factory contract
            const totalPairs = await HBurnFactoryContract.methods.allPairsLength().call();
            // console.log(`Total number of pairs: ${totalPairs}`);

            // Iterate over the pairs and get their addresses
            const pairDetailsPromises = [];
            for (let i = 0; i < totalPairs; i++) {
                const pairAddress = await HBurnFactoryContract.methods.allPairs(i).call();
                pairDetailsPromises.push(getPairDetails(pairAddress));
                // console.log(`Pair #${i}: ${getPairDetails(pairAddress)}`);
                // Further calls can be made here to get details about each pair
            }

            const pairsDetails = await Promise.all(pairDetailsPromises);
            pairsDetails.forEach((details, index) => {
                // console.log(`Pair #${index}:`, details);
                const token0 = {
                    address: details.token0Address,
                    name: details.token0Name,
                    symbol: details.token0Symbol,
                    reserve: details.reserve0,
                };

                // Create an object for token1
                const token1 = {
                    address: details.token1Address,
                    name: details.token1Name,
                    symbol: details.token1Symbol,
                    reserve: details.reserve1,
                };

                // Add the tokens to the array
                tokenList.push(token0, token1);
                tokenList1.push(token0, token1);

                // console.log(`tokenList `, tokenList);

            });
            return pairsDetails;
        } catch (error) {
            console.error('Error fetching pairs:', error);
            return [];
        }
    }

    async function getPairDetails(pairAddress) {
        const pairContract = new web3.eth.Contract(HBurnPairABI, pairAddress);

        // Get reserves and other details
        const reserves = await pairContract.methods.getReserves().call();
        const token0Address = await pairContract.methods.token0().call();
        const token1Address = await pairContract.methods.token1().call();

        // Create contract instances for token0 and token1
        const token0Contract = new web3.eth.Contract(ERC20ABI, token0Address);
        const token1Contract = new web3.eth.Contract(ERC20ABI, token1Address);

        // Fetch the names and symbols of token0 and token1
        const token0Name = await token0Contract.methods.name().call();
        const token0Symbol = await token0Contract.methods.symbol().call();
        const token1Name = await token1Contract.methods.name().call();
        const token1Symbol = await token1Contract.methods.symbol().call();

        return {
            pairAddress,
            reserve0: reserves._reserve0,
            reserve1: reserves._reserve1,
            token0Address,
            token1Address,
            token0Name,
            token0Symbol,
            token1Name,
            token1Symbol,
            // ... include other details here
        };
    }

    const getTokenImage = (tokenSymbol) => {
        // Default image if the token symbol is not found in the mapping
        // const defaultImage = HBARLogo;
        const defaultImage = UnknownLogo;

        // Return the image URL from the mapping or the default image if not found
        return defaultImage;
    };

    const toggleSettings = () => {
        setShowSettings(!showSettings);
    }

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    async function refreshBalance() {
        if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");
        // check network
        let netId = await web3.eth.net.getId()
        if (parseInt(netId) != parseInt(networkId)) {
            return EventBus.publish('info', message);
        }
        let address = (await web3.currentProvider.enable())[0];
        props.setAddress(address);
        let balance = await web3.eth.getBalance(address); //Will give value in.
        let tokenBalance = await TokenContract.methods.balanceOf(address).call({ from: address });
        tokenBalance = web3.utils.fromWei(tokenBalance.toString(), "ether");
        balance = web3.utils.fromWei(balance.toString(), "ether");
        let precision = 3;
        let power = Math.pow(10, precision);
        balance = parseFloat(balance);
        balance = Math.trunc(balance * power) / power;
        setWalletBalance(balance);
        tokenBalance = parseFloat(tokenBalance);
        tokenBalance = Math.trunc(tokenBalance * power) / power;
        setOxBurnBalance(tokenBalance);
    }

    useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleOnWheel);
        };
    }, []);

    useEffect(() => {
        if ((networkId == 296 || networkId == 295) && web3) {
            getPairs().then(setPairs);
            setToken("HBAR");
            setWToken("WHBAR");
            setPSymbol("HBAR");
            setSwapValue1("HBAR");
            setSwapValue2("HBAR");
            setPoolValue1("HBAR");
            setPoolValue2("HBAR");

            setSelectSwapToken1("HBAR");
            // setPrimaryToken("0x3d371d3fe41ee35d366dbeb08c53379605e4895b");
            setSelectSwapToken2("");

            setSelectPoolToken1("HBAR");
            setSelectPoolToken2("");
        }
    }, [networkId, web3]);

    const incrementCount = () => {
        if (parseFloat(primaryAmountPMin) > 0) {
            setPrimaryAmountPMin(parseFloat(primaryAmountPMin) + 1);
        } else {
            setPrimaryAmountPMin("");
        }
    };

    const decrementCount = () => {
        if (parseFloat(primaryAmountPMin) > 0) {
            setPrimaryAmountPMin(parseFloat(primaryAmountPMin) - 1);
        } else {
            setPrimaryAmountPMin("");
        }
    };

    const increment = () => {
        if (parseFloat(secondaryAmountPMin) > 0) {
            setSecondaryAmountPMin(parseFloat(secondaryAmountPMin) - 1);
        } else {
            setSecondaryAmountPMin("");
        }
    };

    const decrement = () => {
        if (parseFloat(secondaryAmountPMin) > 0) {
            setSecondaryAmountPMin(parseFloat(secondaryAmountPMin) - 1);
        } else {
            setSecondaryAmountPMin("");
        }
    };

    async function handleSlippage(e) {
        if ([e.target.name] == "slippageValue") {
            if (Number(e.target.value) > 60) {
                EventBus.publish("error", `Slippage cannot be larger than 60%!`);
                return;
            } else {
                if (e.target.value === "") {
                    EventBus.publish("info", `Slippage should be minimum at 0.3%`);
                    setSlippageValue(e.target.value);
                } else {
                    setSlippageValue(e.target.value);
                }
            }
        }

        if ([e.target.name] == "iDeadlineValue") {
            if (e.target.value === "") {
                setIDeadlineValue(30);
            } else {
                setIDeadlineValue(parseInt(e.target.value));
                let newDateObj = moment(Date.now()).add(parseInt(e.target.value), 'm').toDate();
                setDeadlineValue(parseFloat(newDateObj));
            }
        }
    }

    async function autoSlippage(e) {
        setSlippageValue(parseFloat(0.3));
    }

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));

        if ([e.target.name] == "primaryAmountP") {
            if (parseFloat(e.target.value) > 0) {
                setPrimaryAmountP(parseFloat(e.target.value));
            } else {
                setPrimaryAmountP("");
            }
        } else if ([e.target.name] == "secondaryAmountP") {
            if (parseFloat(e.target.value) > 0) {
                setSecondaryAmountP(parseFloat(e.target.value));
            } else {
                setSecondaryAmountP("");
            }
        } else if ([e.target.name] == "liquidityAmount") {
            if ((selectPoolToken1 !== "" || primaryToken !== "") && (selectPoolToken2 !== "" || secondaryToken !== "")) {
                if (parseFloat(e.target.value) > 0) {
                    if (parseFloat(e.target.value) < parseFloat(liquidityAmountActual)) {
                        setLiquidityAmount(parseFloat(e.target.value));
                    } else {
                        EventBus.publish("error", `You have not added this amount of liquidity!`);
                        return;
                    }
                } else {
                    setLiquidityAmount("");
                }
            } else {
                EventBus.publish("error", `Please select token pair first!`);
                return;
            }
        } else if ([e.target.name] == "primaryAmountPMin") {
            if (parseFloat(e.target.value) > 0) {
                setPrimaryAmountPMin(parseFloat(e.target.value));
            } else {
                setPrimaryAmountPMin("");
            }
        } else if ([e.target.name] == "secondaryAmountPMin") {
            if (parseFloat(e.target.value) > 0) {
                setSecondaryAmountPMin(parseFloat(e.target.value));
            } else {
                setSecondaryAmountPMin("");
            }
        } else if ([e.target.name] == "primaryToken") {
            if (e.target.value == "") {
                setPrimaryToken("");
            } else if ((e.target.value).length >= 42) {
                setPrimaryToken(e.target.value);
                var code = await web3.eth.getCode(e.target.value.toLowerCase());
                if (code === "0x") {
                    EventBus.publish("error", `Invalid Token Address!`);
                    setPrimaryToken("");
                    return;
                } else {
                    setPoolValue1("");
                    setSelectPoolToken1("");
                    await waitFor(1500);
                    let primaryContract = new web3.eth.Contract(ERC20ABI, e.target.value);
                    let primarySymbol = await primaryContract.methods.symbol().call();
                    setPSymbol(primarySymbol);
                    if (selectPoolToken2 !== "" || secondaryToken !== "") {
                        let publicAddress = (await web3.currentProvider.enable())[0];

                        let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
                        let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

                        if (selectPoolToken2 == "HBAR") {
                            secondaryToken = HBAR;
                        } else if (selectPoolToken2 == "WHBAR") {
                            secondaryToken = WHBAR;
                        }

                        if (typeof publicAddress === "undefined" || publicAddress == null) {
                            EventBus.publish("error", `Please connect your metamask!`);
                            return;
                        } else {
                            let pair = await HBurnFactoryContract.methods.getPair(e.target.value.toLowerCase(), secondaryToken.toLowerCase()).call();

                            if (pair == "0x0000000000000000000000000000000000000000") {
                                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                                return;
                            } else {
                                let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                                let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                                balanceLiquidity = (balanceLiquidity / 100) * 99.6;
                                balanceLiquidity = (balanceLiquidity / (10 ** 8)).toString();
                                // balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                                setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                                setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                            }
                        }
                    }
                }
            } else {
                setPrimaryToken(e.target.value)
            }
        } else if ([e.target.name] == "secondaryToken") {
            if (e.target.value == "") {
                setSecondaryToken("");
            } else if ((e.target.value).length >= 42) {
                setSecondaryToken(e.target.value);
                var code = await web3.eth.getCode(e.target.value.toLowerCase());
                if (code === "0x") {
                    EventBus.publish("error", `Invalid Token Address!`);
                    setSecondaryToken("");
                    return;
                } else {
                    setPoolValue2("");
                    setSelectPoolToken2("");
                    await waitFor(1500);
                    let secondaryContract = new web3.eth.Contract(ERC20ABI, e.target.value);
                    let secondarySymbol = await secondaryContract.methods.symbol().call();
                    setSSymbol(secondarySymbol);
                    if (selectPoolToken1 !== "" || primaryToken !== "") {
                        let publicAddress = (await web3.currentProvider.enable())[0];

                        let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
                        let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

                        if (selectPoolToken1 == "HBAR") {
                            primaryToken = HBAR;
                        } else if (selectPoolToken1 == "WHBAR") {
                            primaryToken = WHBAR;
                        }

                        if (typeof publicAddress === "undefined" || publicAddress == null) {
                            EventBus.publish("error", `Please connect your metamask!`);
                            return;
                        } else {
                            let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), e.target.value.toLowerCase()).call();

                            if (pair == "0x0000000000000000000000000000000000000000") {
                                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                                return;
                            } else {
                                let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                                let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                                balanceLiquidity = (balanceLiquidity / (10 ** 8)).toString();
                                // balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                                balanceLiquidity = (balanceLiquidity / 100) * 99.6;
                                setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                                setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                            }
                        }
                    }
                }
            } else {
                setSecondaryToken(e.target.value)
            }
        } else if ([e.target.name] == "primaryAmount") {
            if (parseFloat(e.target.value) <= 0) {
                setPrimaryAmount(e.target.value);
                setSecondaryAmount(e.target.value);
            } else {
                setPrimaryAmount(parseFloat(e.target.value));
                if (
                    parseFloat(e.target.value) >= 0 &&
                    primaryToken === "" &&
                    selectSwapToken1 === "" &&
                    pSymbol === ""
                ) {
                    EventBus.publish("error", `Add Token-A To Swap`);
                    return;
                }

                if (selectSwapToken1 == "WHBAR" || selectSwapToken1 == "HBAR") {
                    let publicAddress = (await web3.currentProvider.enable())[0];
                    if (typeof publicAddress === "undefined" || publicAddress == null) {
                        EventBus.publish("error", `Please connect your metamask!`);
                        return;
                    } else {

                        let primaryBalance = await web3.eth.getBalance(publicAddress);
                        primaryBalance = web3.utils.fromWei(
                            primaryBalance.toString(),
                            "ether"
                        );

                        if (parseFloat(e.target.value) > primaryBalance) {
                            EventBus.publish("error", `Insufficient Balance!`);
                            return;
                        }

                        if (selectSwapToken1 !== "" && (secondaryToken !== "" || selectSwapToken2 !== "")) {
                            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b";
                            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b";

                            if (selectSwapToken1 == "HBAR") {
                                primaryToken = HBAR;
                            } else if (selectSwapToken1 == "WHBAR") {
                                primaryToken = WHBAR;
                            }

                            if (selectSwapToken2 == "HBAR") {
                                secondaryToken = HBAR;
                            } else if (selectSwapToken2 == "WHBAR") {
                                secondaryToken = WHBAR;
                            }

                            let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();

                            if (pair == "0x0000000000000000000000000000000000000000") {
                                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                                return;
                            } else {
                                if (e.target.value === "") {
                                    setSecondaryAmount("");
                                }
                                if (primaryToken == HBAR || primaryToken == WHBAR) {
                                    primaryAmount = (e.target.value * (10 ** 8)).toString();
                                } else {
                                    let Atoken = new web3.eth.Contract(ERC20ABI, primaryToken);
                                    let decimals = await Atoken.methods.decimals().call();
                                    if (decimals == 18) {
                                        primaryAmount = web3.utils.toWei(e.target.value.toString(), "ether");
                                    } else {
                                        primaryAmount = (e.target.value * 10 ** decimals).toString();
                                    }
                                }
                                let expectedAmount = await HBurnRouterContract.methods.getAmountsOut(primaryAmount, [primaryToken.toLowerCase(), secondaryToken.toLowerCase()]).call();
                                let sAmount = expectedAmount[1];
                                if (secondaryToken == HBAR || secondaryToken == WHBAR) {
                                    sAmount = (sAmount / (10 ** 8)).toString();
                                } else {
                                    let Atoken = new web3.eth.Contract(ERC20ABI, secondaryToken);
                                    let decimals = await Atoken.methods.decimals().call();
                                    if (decimals == 18) {
                                        sAmount = web3.utils.fromWei(sAmount.toString(), "ether");
                                    } else {
                                        sAmount = (sAmount / (10 ** decimals)).toString();
                                    }
                                }
                                setSecondaryAmount(parseFloat(toFixed(sAmount, 'balance')));
                            }
                        } else {
                            EventBus.publish("error", `Token B is missing!`);
                            return;
                        }
                    }
                } else {
                    let publicAddress = (await web3.currentProvider.enable())[0];
                    if (typeof publicAddress === "undefined" || publicAddress == null) {
                        EventBus.publish("error", `Please connect your metamask!`);
                        return;
                    } else {
                        let primaryContract = new web3.eth.Contract(ERC20ABI, primaryToken);
                        let primaryBalance = await primaryContract.methods.balanceOf(publicAddress).call();
                        let decimals = await primaryContract.methods.decimals().call();

                        primaryBalance = (primaryBalance / (10 ** decimals)).toString();

                        if (parseFloat(e.target.value) > primaryBalance) {
                            EventBus.publish("error", `Insufficient ${pSymbol} Balance!`);
                            return;
                        }

                        if (selectSwapToken2 !== "") {
                            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b";
                            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b";

                            if (selectSwapToken2 == "HBAR") {
                                secondaryToken = HBAR;
                            } else if (selectSwapToken2 == "WHBAR") {
                                secondaryToken = WHBAR;
                            }
                            if (selectSwapToken1 == "HBAR") {
                                primaryToken = HBAR;
                            } else if (selectSwapToken1 == "WHBAR") {
                                primaryToken = WHBAR;
                            }
                            let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();
                            if (pair == "0x0000000000000000000000000000000000000000") {
                                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                                return;
                            } else {
                                if (e.target.value === "") {
                                    setSecondaryAmount("");
                                }
                                if (primaryToken == HBAR || primaryToken == WHBAR) {
                                    primaryAmount = (e.target.value * (10 ** 8)).toString();
                                } else {
                                    let Atoken = new web3.eth.Contract(ERC20ABI, primaryToken);
                                    let decimals = await Atoken.methods.decimals().call();
                                    if (decimals == 18) {
                                        primaryAmount = web3.utils.toWei(e.target.value.toString(), "ether");
                                    } else {
                                        primaryAmount = (e.target.value * 10 ** decimals).toString();
                                    }
                                }
                                let expectedAmount = await HBurnRouterContract.methods.getAmountsOut(primaryAmount, [primaryToken.toLowerCase(), secondaryToken.toLowerCase()]).call();
                                let sAmount = expectedAmount[1];
                                if (secondaryToken == HBAR || secondaryToken == WHBAR) {
                                    sAmount = (sAmount / (10 ** 8)).toString();
                                } else {
                                    let Atoken = new web3.eth.Contract(ERC20ABI, secondaryToken);
                                    let decimals = await Atoken.methods.decimals().call();
                                    if (decimals == 18) {
                                        sAmount = web3.utils.fromWei(sAmount.toString(), "ether");
                                    } else {
                                        sAmount = (sAmount / (10 ** decimals)).toString();
                                    }
                                }
                                setSecondaryAmount(parseFloat(toFixed(sAmount, 'balance')));
                            }
                        } else if (secondaryToken !== "") {
                            let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();
                            if (pair == "0x0000000000000000000000000000000000000000") {
                                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                                return;
                            } else {
                                if (e.target.value === "") {
                                    setSecondaryAmount("");
                                }
                                if (primaryToken == HBAR || primaryToken == WHBAR) {
                                    primaryAmount = (e.target.value * (10 ** 8)).toString();
                                } else {
                                    let Atoken = new web3.eth.Contract(ERC20ABI, primaryToken);
                                    let decimals = await Atoken.methods.decimals().call();
                                    if (decimals == 18) {
                                        primaryAmount = web3.utils.toWei(e.target.value.toString(), "ether");
                                    } else {
                                        primaryAmount = (e.target.value * 10 ** decimals).toString();
                                    }
                                }
                                let expectedAmount = await HBurnRouterContract.methods.getAmountsOut(primaryAmount, [primaryToken.toLowerCase(), secondaryToken.toLowerCase()]).call();
                                let sAmount = expectedAmount[1];
                                if (secondaryToken == HBAR || secondaryToken == WHBAR) {
                                    sAmount = (sAmount / (10 ** 8)).toString();
                                } else {
                                    let Atoken = new web3.eth.Contract(ERC20ABI, secondaryToken);
                                    let decimals = await Atoken.methods.decimals().call();
                                    if (decimals == 18) {
                                        sAmount = web3.utils.fromWei(sAmount.toString(), "ether");
                                    } else {
                                        sAmount = (sAmount / (10 ** decimals)).toString();
                                    }
                                }
                                setSecondaryAmount(parseFloat(toFixed(sAmount, 'balance')));
                            }
                        } else {
                            EventBus.publish("error", `Token B is missing!`);
                            return;
                        }
                    }
                }
            }
        } else if ([e.target.name] == "secondaryAmount") {
            if (e.target.value <= 0) {
                setPrimaryAmount(e.target.value);
                setSecondaryAmount(e.target.value);
            } else {
                setSecondaryAmount(parseFloat(e.target.value));
                if (
                    parseFloat(e.target.value) > 0 &&
                    secondaryToken === "" &&
                    selectSwapToken2 === ""
                ) {
                    EventBus.publish("error", `Add Token-B To Swap`);
                    return;
                }
            }
        }
    }

    async function handleInput(e) {
        let publicAddress = (await web3.currentProvider.enable())[0];
        if (publicAddress == null) {
            EventBus.publish("error", `Please connect your metamask!`);
            return;
        }
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));

        if ([e.target.name] == "primaryToken") {
            if (e.target.value == "") {
                setPrimaryToken("");
            } else if ((e.target.value).length >= 42) {
                setPrimaryToken(e.target.value);
                var code = await web3.eth.getCode(e.target.value.toLowerCase());
                if (code === "0x") {
                    EventBus.publish("error", `Invalid Token Address!`);
                    setPrimaryToken("");
                    return;
                } else {
                    setSwapValue1("");
                    setSelectSwapToken1("");
                    await waitFor(3000);
                    let primaryContract = new web3.eth.Contract(
                        ERC20ABI,
                        e.target.value.toLowerCase()
                    );
                    let primarySymbol = await primaryContract.methods.symbol().call();
                    setPSymbol(primarySymbol);
                }
            } else {
                setPrimaryToken(e.target.value)
            }
        } else if ([e.target.name] == "secondaryToken") {
            if (e.target.value == "") {
                setSecondaryToken("");
            } else if ((e.target.value).length >= 42) {
                setSecondaryToken(e.target.value);
                var code = await web3.eth.getCode(e.target.value.toLowerCase());
                if (code === "0x") {
                    EventBus.publish("error", `Invalid Token Address!`);
                    setSecondaryToken("");
                    return;
                } else {
                    setSwapValue2("");
                    setSelectSwapToken2("");
                    await waitFor(3000);
                    let secondaryContract = new web3.eth.Contract(
                        ERC20ABI,
                        e.target.value.toLowerCase()
                    );
                    let secondarySymbol = await secondaryContract.methods.symbol().call();
                    setSSymbol(secondarySymbol);
                }
            } else {
                setSecondaryToken(e.target.value)
            }
        }
    }

    const clearStates = (e) => {
        setPrimaryAmountP("");
        setSecondaryAmountP("");
        setPrimaryAmountPMin("");
        setSecondaryAmountPMin("");
        setPrimaryToken("");
        setSecondaryToken("");
        setPSymbol("");
        setSSymbol("");
        setLiquidityAmount("");
        setLiquidityAmountActual(0);
        setSelectPoolToken1("");
        setSelectPoolToken2("");
    };

    async function handleSwapValue1(data) {
        setSwapValue1(data);
        setSelectSwapToken1(data);
        setPSymbol(data);
        setPrimaryToken("");
        if (
            selectSwapToken2 == "HBAR" ||
            selectSwapToken2 == "WHBAR"
        ) {
            setSecondaryToken("");
            setSelectSwapToken2("");
            setSSymbol("");
        }
        setSwapModal1(false);
    }

    async function handleSwapValue2(data) {
        setSwapValue2(data);
        setSelectSwapToken2(data);
        setSSymbol(data);
        setSecondaryToken("");
        if (
            selectSwapToken1 == "HBAR" ||
            selectSwapToken1 == "WHBAR"
        ) {
            setPrimaryToken("");
            setSelectSwapToken1("");
            setPSymbol("");
        }
        setSwapModal2(false);
    }

    async function handleSwapValue11(data) {

        const selectedToken = tokenList.find(token => token.address === data);

        // If the token is found, use its symbol, otherwise default to an empty string or previous state
        const tokenSymbol = selectedToken ? selectedToken.symbol : '';

        setPrimaryToken(data);
        setSelectSwapToken1(tokenSymbol);
        setPSymbol(tokenSymbol);
        setSwapValue1("");

        if (
            selectSwapToken2 == tokenSymbol
        ) {
            setSecondaryToken("");
            setSelectSwapToken2("");
            setSSymbol("");
        }

        // Create a copy of the tokenList filtering out all tokens that aren't present in a pair with the selected token
        const filteredTokenList = tokenList.filter(token =>
            pairs.some(pair =>
                (pair.token0Address === data && token.address === pair.token1Address) ||
                (pair.token1Address === data && token.address === pair.token0Address)
            )
        );

        setTokenList1(filteredTokenList);
        setSwapModal1(false);
    }

    async function handleSwapValue22(data) {
        const selectedToken = tokenList1.find(token => token.address === data);

        // If the token is found, use its symbol, otherwise default to an empty string or previous state
        const tokenSymbol = selectedToken ? selectedToken.symbol : '';

        setSecondaryToken(data);
        setSelectSwapToken2(tokenSymbol);
        setSSymbol(tokenSymbol);
        setSwapValue2("");
        if (
            selectSwapToken1 == tokenSymbol
        ) {
            setPrimaryToken("");
            setSelectSwapToken1("");
            setPSymbol("");
        }
        setSwapModal2(false);
    }

    async function handlePoolValue1(data) {
        setPoolValue1(data);
        setSelectPoolToken1(data);
        setPSymbol(data);
        setPrimaryToken("");

        if (selectPoolToken2 !== "" || secondaryToken !== "") {
            let publicAddress = (await web3.currentProvider.enable())[0];

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectPoolToken2 == "HBAR") {
                secondaryToken = HBAR;
            } else if (selectPoolToken2 == "WHBAR") {
                secondaryToken = WHBAR;
            }

            if (typeof publicAddress === "undefined" || publicAddress == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
            } else {
                let pair = await HBurnFactoryContract.methods.getPair(data.toLowerCase(), secondaryToken.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
                    return;
                } else {
                    let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                    let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                    balanceLiquidity = (balanceLiquidity / (10 ** 8)).toString();
                    // balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                    balanceLiquidity = (balanceLiquidity / 100) * 99.6;
                    setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                    setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
            }
        }

        if (
            selectPoolToken2 == "HBAR" ||
            selectPoolToken2 == "WHBAR"
        ) {
            setSecondaryToken("");
            setSelectPoolToken2("");
            setSSymbol("");
        }
        setPoolModal1(false);
    }

    async function handlePoolValue11(data) {
        const selectedToken = tokenList.find(token => token.address === data);

        // If the token is found, use its symbol, otherwise default to an empty string or previous state
        const tokenSymbol = selectedToken ? selectedToken.symbol : '';

        setPrimaryToken(data);
        setSelectPoolToken1(tokenSymbol);
        setPSymbol(tokenSymbol);
        setPoolValue1("");

        if (selectPoolToken2 !== "" || secondaryToken !== "") {
            let publicAddress = (await web3.currentProvider.enable())[0];

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectPoolToken2 == "HBAR") {
                secondaryToken = HBAR;
            } else if (selectPoolToken2 == "WHBAR") {
                secondaryToken = WHBAR;
            }

            if (typeof publicAddress === "undefined" || publicAddress == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
            } else {
                let pair = await HBurnFactoryContract.methods.getPair(data.toLowerCase(), secondaryToken.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
                    return;
                } else {
                    let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                    let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                    balanceLiquidity = (balanceLiquidity / (10 ** 8)).toString();
                    // balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                    balanceLiquidity = (balanceLiquidity / 100) * 99.6;
                    setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                    setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
            }
        }

        if (
            selectPoolToken2 == tokenSymbol
        ) {
            setSecondaryToken("");
            setSelectPoolToken2("");
            setSSymbol("");
        }

        setTokenList1(tokenList);
        setPoolModal1(false);
    }

    async function handlePoolValue2(data) {
        setPoolValue2(data);
        setSelectPoolToken2(data);
        setSSymbol(data);
        setSecondaryToken("");

        if (selectPoolToken1 !== "" || primaryToken !== "") {
            let publicAddress = (await web3.currentProvider.enable())[0];

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectPoolToken1 == "HBAR") {
                primaryToken = HBAR;
            } else if (selectPoolToken1 == "WHBAR") {
                primaryToken = WHBAR;
            }

            if (typeof publicAddress === "undefined" || publicAddress == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
            } else {
                let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), data.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
                    return;
                } else {
                    let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                    let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                    balanceLiquidity = (balanceLiquidity / (10 ** 8)).toString();
                    // balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                    balanceLiquidity = (balanceLiquidity / 100) * 99.6;
                    setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                    setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
            }
        }

        if (
            selectPoolToken1 == "HBAR" ||
            selectPoolToken1 == "WHBAR"
        ) {
            setPrimaryToken("");
            setSelectPoolToken1("");
            setPSymbol("");
        }
        setPoolModal2(false);
    }

    async function handlePoolValue22(data) {
        const selectedToken = tokenList1.find(token => token.address === data);

        // If the token is found, use its symbol, otherwise default to an empty string or previous state
        const tokenSymbol2 = selectedToken ? selectedToken.symbol : '';

        setSecondaryToken(data);
        setSelectPoolToken2(tokenSymbol2);
        setSSymbol(tokenSymbol2);
        setPoolValue2("");

        if (selectPoolToken1 !== "" || primaryToken !== "") {
            // let publicAddress = (await web3.currentProvider.enable())[0];
            let publicAddress = (await web3.currentProvider.enable())[0];

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectPoolToken1 == "HBAR") {
                primaryToken = HBAR;
            } else if (selectPoolToken1 == "WHBAR") {
                primaryToken = WHBAR;
            }


            if (typeof publicAddress === "undefined" || publicAddress == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
            } else {
                let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), data.toLowerCase()).call();

                if (pair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish("info", `Pair Does not exist for Token-A & Token-B!`);
                    return;
                } else {
                    let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                    let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                    balanceLiquidity = (balanceLiquidity / (10 ** 8)).toString();
                    // balanceLiquidity = web3.utils.fromWei(balanceLiquidity.toString(), "ether");
                    balanceLiquidity = (balanceLiquidity / 100) * 99.6;
                    setLiquidityAmount(parseFloat(toFixed(balanceLiquidity, 'balance')));
                    setLiquidityAmountActual(parseFloat(toFixed(balanceLiquidity, 'balance')));
                }
            }
        }

        if (
            selectPoolToken1 == tokenSymbol2
        ) {
            setPrimaryToken("");
            setSelectPoolToken1("");
            setPSymbol("");
        }

        setTokenList1(tokenList);
        setPoolModal2(false);
    }

    async function handleFlip() {
        let arrayP = [];
        let arrayS = [];
        arrayP.push(
            swapValue1,
            selectSwapToken1,
            pSymbol,
            primaryToken,
            primaryAmount
        );
        arrayS.push(
            swapValue2,
            selectSwapToken2,
            sSymbol,
            secondaryToken,
            secondaryAmount
        );
        setSwapValue1(arrayS[0]);
        setSelectSwapToken1(arrayS[1]);
        setPSymbol(arrayS[2]);
        setPrimaryToken(arrayS[3]);
        setPrimaryAmount(arrayS[4]);

        setSwapValue2(arrayP[0]);
        setSelectSwapToken2(arrayP[1]);
        setSSymbol(arrayP[2]);
        setSecondaryToken(arrayP[3]);
        setSecondaryAmount(arrayP[4]);
    }

    async function addLiquidity() {
        try {
            let publicAddress = (await web3.currentProvider.enable())[0];

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectPoolToken1 == "HBAR") {
                primaryToken = HBAR;
            } else if (selectPoolToken1 == "WHBAR") {
                primaryToken = WHBAR;
            }

            if (selectPoolToken2 == "HBAR") {
                EventBus.publish("error", `Please select HBAR as token A!`);
                return;
            } else if (selectPoolToken2 == "WHBAR") {
                EventBus.publish("error", `Please select WHBAR as token A!`);
                return;
            }

            if (primaryToken == "") {
                EventBus.publish("error", `Token A is missing!`);
                return;
            }
            if (secondaryToken == "") {
                EventBus.publish("error", `Token B is missing!`);
                return;
            }
            if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
                EventBus.publish(
                    "error",
                    `Token A cannot have same address as Token B!`
                );
                return;
            }
            if (parseFloat(primaryAmountP) <= 0) {
                EventBus.publish("error", `Enter Token A Amount!`);
                return;
            }
            if (parseFloat(secondaryAmountP) <= 0) {
                EventBus.publish("error", `Enter Token B Amount!`);
                return;
            }
            if (parseFloat(primaryAmountPMin) <= 0) {
                EventBus.publish("error", `Enter Token A Min Amount!`);
                return;
            }
            if (parseFloat(secondaryAmountPMin) <= 0) {
                EventBus.publish("error", `Enter Token B Min Amount!`);
                return;
            }

            primaryToken = primaryToken.toLowerCase();
            secondaryToken = secondaryToken.toLowerCase();

            let primaryContract = new web3.eth.Contract(ERC20ABI, primaryToken);
            let secondaryContract = new web3.eth.Contract(ERC20ABI, secondaryToken);

            if (primaryToken == HBAR || primaryToken == WHBAR) {
                // primaryAmountP = web3.utils.toWei(primaryAmountP.toString(), "ether");
                // primaryAmountPMin = web3.utils.toWei(primaryAmountPMin.toString(), "ether");
                primaryAmountP = (primaryAmountP * (10 ** 8)).toString();
                primaryAmountPMin = (primaryAmountPMin * (10 ** 8)).toString();
            } else {
                let decimals = await primaryContract.methods.decimals().call();
                if (decimals == 18) {
                    primaryAmountP = web3.utils.toWei(primaryAmountP.toString(), "ether");
                    primaryAmountPMin = web3.utils.toWei(primaryAmountPMin.toString(), "ether");
                } else {
                    primaryAmountP = (primaryAmountP * (10 ** decimals)).toString();
                    primaryAmountPMin = (primaryAmountPMin * (10 ** decimals)).toString();
                }
            }

            if (secondaryToken == HBAR || secondaryToken == WHBAR) {
                // secondaryAmountP = web3.utils.toWei(secondaryAmountP.toString(), "ether");
                // secondaryAmountPMin = web3.utils.toWei(secondaryAmountPMin.toString(), "ether");
                secondaryAmountP = (secondaryAmountP * (10 ** 8)).toString();
                secondaryAmountPMin = (secondaryAmountPMin * (10 ** 8)).toString();
            } else {
                let decimals = await secondaryContract.methods.decimals().call();
                if (decimals == 18) {
                    secondaryAmountP = web3.utils.toWei(secondaryAmountP.toString(), "ether");
                    secondaryAmountPMin = web3.utils.toWei(secondaryAmountPMin.toString(), "ether");
                } else {
                    secondaryAmountP = (secondaryAmountP * (10 ** decimals)).toString();
                    secondaryAmountPMin = (secondaryAmountPMin * (10 ** decimals)).toString();
                }
            }

            let primaryBalance = await primaryContract.methods
                .balanceOf(publicAddress)
                .call();
            let secondaryBalance = await secondaryContract.methods
                .balanceOf(publicAddress)
                .call();

            let userBalance = await web3.eth.getBalance(publicAddress);

            if (
                primaryToken.toLowerCase() === "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                if (parseFloat(primaryAmountP) > userBalance) {
                    EventBus.publish(
                        "error",
                        `Token A: You dont have enough balance to deposit!`
                    );
                    return;
                }
            } else {
                if (parseFloat(primaryAmountP) > primaryBalance) {
                    EventBus.publish(
                        "error",
                        `Token A: You dont have enough balance to deposit!`
                    );
                    return;
                }
            }

            if (
                secondaryToken.toLowerCase() === "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                if (parseFloat(secondaryAmountP) > userBalance) {
                    EventBus.publish(
                        "error",
                        `Token B: You dont have enough balance to deposit!`
                    );
                    return;
                }
            } else {
                if (parseFloat(secondaryAmountP) > secondaryBalance) {
                    EventBus.publish(
                        "error",
                        `Token B: You dont have enough balance to deposit!`
                    );
                    return;
                }
            }

            // Set Amount
            const amountInPrimary = primaryAmountP;
            const amountInP = amountInPrimary.toString();

            const amountInSecondary = secondaryAmountP;
            const amountInS = amountInSecondary.toString();

            // console.log("***** amountInP", primaryAmountP);
            // console.log("***** amountInS", secondaryAmountP);

            /** Add Liquidity **/
            props.toggleLoader({
                message: "Add Liquidity in Progress...",
                status: true,
            });

            if (
                primaryToken.toLowerCase() !== "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                console.log("Approving Primary Token ...");
                await primaryContract.methods
                    .approve(HBurnRouterAddress, amountInP)
                    .send({
                        from: publicAddress,
                        gas: 2000000,
                    });
            }

            if (
                secondaryToken.toLowerCase() !== "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                console.log("Approving Secondary Token ...");
                await secondaryContract.methods
                    .approve(HBurnRouterAddress, amountInS)
                    .send({
                        from: publicAddress,
                        gas: 2000000,
                    });
            }

            console.log("Adding Liquidity...");

            let result;

            if (
                primaryToken.toLowerCase() === "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                result = await HBurnRouterContract.methods.addLiquidityETH(
                    secondaryToken,
                    secondaryAmountP,
                    secondaryAmountPMin,
                    primaryAmountPMin,
                    publicAddress,
                    web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        to: HBurnRouterAddress,
                        value: web3.utils.toHex(primaryAmountP),
                        data: result.encodeABI(),
                    })
                    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                    .on("receipt", async (receipt) => {
                        refreshBalance();
                        setPSymbol("");
                        setSSymbol("");
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setPrimaryAmountP("");
                        setSecondaryAmountP("");
                        setPrimaryAmountPMin("");
                        setSecondaryAmountPMin("");
                        setPoolValue1("");
                        setPoolValue2("");
                        setSelectPoolToken1("");
                        setSelectPoolToken2("");
                        setAddLiquidityModal(false);
                        getPairs().then(setPairs);
                        props.toggleLoader({ status: false });
                        EventBus.publish("success", `Liquidity Added Successfully`);
                    });
            } else {
                result = await HBurnRouterContract.methods.addLiquidity(
                    primaryToken,
                    secondaryToken,
                    primaryAmountP,
                    secondaryAmountP,
                    primaryAmountPMin,
                    secondaryAmountPMin,
                    publicAddress,
                    web3.utils.toHex(Math.round(Date.now() / 1000) + 60 * 20)
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        to: HBurnRouterAddress,
                        data: result.encodeABI(),
                    })
                    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                    .on("receipt", async (receipt) => {
                        refreshBalance();
                        setPSymbol("");
                        setSSymbol("");
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setPrimaryAmountP("");
                        setSecondaryAmountP("");
                        setPrimaryAmountPMin("");
                        setSecondaryAmountPMin("");
                        setPoolValue1("");
                        setPoolValue2("");
                        setSelectPoolToken1("");
                        setSelectPoolToken2("");
                        setAddLiquidityModal(false);
                        getPairs().then(setPairs);
                        props.toggleLoader({ status: false });
                        EventBus.publish("success", `Liquidity Added Successfully`);
                    });
            }
        } catch (e) {
            console.log("********Error = ", e);
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            props.toggleLoader({
                message: "Add Liquidity Failed!",
                status: false,
            });
            EventBus.publish("error", `Unable To Add Liquidity!`);
        }
    }

    async function removeLiquidity() {
        try {

            let publicAddress = (await web3.currentProvider.enable())[0];

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectPoolToken1 == "HBAR") {
                primaryToken = HBAR;
            } else if (selectPoolToken1 == "WHBAR") {
                primaryToken = WHBAR;
            }

            if (selectPoolToken2 == "HBAR") {
                EventBus.publish("error", `Please select HBAR as token A!`);
                return;
            } else if (selectPoolToken2 == "WHBAR") {
                EventBus.publish("error", `Please select WHBAR as token A!`);
                return;
            }

            if (primaryToken == "") {
                EventBus.publish("error", `Token A is missing!`);
                return;
            }
            if (secondaryToken == "") {
                EventBus.publish("error", `Token B is missing!`);
                return;
            }
            if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
                EventBus.publish(
                    "error",
                    `Token A cannot have same address as Token B!`
                );
                return;
            }

            let pair = await HBurnFactoryContract.methods.getPair(primaryToken.toLowerCase(), secondaryToken.toLowerCase()).call();

            if (pair == "0x0000000000000000000000000000000000000000") {
                EventBus.publish("error", `Pair Does not exist for Token-A & Token-B!`);
                return;
            } else {
                let pairContract = new web3.eth.Contract(HBurnPairABI, pair);
                let balanceLiquidity = await pairContract.methods.balanceOf(publicAddress).call();
                console.log("*** balanceLiquidity", balanceLiquidity);
                liquidityAmount = (liquidityAmount * (10 ** 8)).toString();
                console.log("*** liquidityAmount", liquidityAmount);
                // liquidityAmount = web3.utils.toWei(liquidityAmount.toString(), "ether");
                if (parseFloat(liquidityAmount) > parseFloat(balanceLiquidity)) {
                    EventBus.publish("error", `You have not added this amount of liquidity!`);
                }

                props.toggleLoader({
                    message: "Approval in Progress...",
                    status: true,
                });

                await pairContract.methods
                    .approve(HBurnRouterAddress, parseInt(liquidityAmount))
                    .send({
                        from: publicAddress,
                        gas: 2000000,
                    });
            }

            if (parseFloat(liquidityAmount) <= 0) {
                EventBus.publish("error", `Enter Liquidity Amount!`);
                return;
            }

            const balanceWei = await web3.eth.getBalance(publicAddress);
            // const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (parseFloat(balanceWei) == 0) return EventBus.publish("error", `Insufficient balance for transaction`);


            liquidityAmount = liquidityAmount.toString();

            /** Remove Liquidity **/
            props.toggleLoader({
                message: "Remove Liquidity in Progress...",
                status: true,
            });

            console.log("Removing Liquidity...");

            let result;

            if (
                primaryToken.toLowerCase() === "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                result = await HBurnRouterContract.methods.removeLiquidityETH(
                    secondaryToken,
                    parseInt(liquidityAmount),
                    1,
                    1,
                    publicAddress,
                    web3.utils.toHex(Math.ceil(deadlineValue / 1000))
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        to: HBurnRouterAddress,
                        data: result.encodeABI(),
                    })
                    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                    .on("receipt", async (receipt) => {
                        refreshBalance();
                        setPSymbol("");
                        setSSymbol("");
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setPrimaryAmountP("");
                        setSecondaryAmountP("");
                        setPrimaryAmountPMin("");
                        setSecondaryAmountPMin("");
                        setPoolValue1("");
                        setPoolValue2("");
                        setSelectPoolToken1("");
                        setSelectPoolToken2("");
                        setLiquidityAmount("");
                        setLiquidityAmountActual(0);
                        setRemoveLiquidityModal(false);
                        props.toggleLoader({ status: false });
                        EventBus.publish("success", `Liquidity Redeemed Successfully`);
                    });
            } else {
                result = await HBurnRouterContract.methods.removeLiquidity(
                    primaryToken,
                    secondaryToken,
                    liquidityAmount,
                    1,
                    1,
                    publicAddress,
                    web3.utils.toHex(Math.ceil(deadlineValue / 1000))
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        to: HBurnRouterAddress,
                        data: result.encodeABI(),
                    })
                    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                    .on("receipt", async (receipt) => {
                        refreshBalance();
                        setPSymbol("");
                        setSSymbol("");
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setPrimaryAmountP("");
                        setSecondaryAmountP("");
                        setPrimaryAmountPMin("");
                        setSecondaryAmountPMin("");
                        setPoolValue1("");
                        setPoolValue2("");
                        setSelectPoolToken1("");
                        setSelectPoolToken2("");
                        setLiquidityAmount("");
                        setLiquidityAmountActual(0);
                        props.toggleLoader({ status: false });
                        setRemoveLiquidityModal(false);
                        EventBus.publish("success", `Liquidity Redeemed Successfully`);
                    });
            }
        } catch (e) {
            console.log("********Error = ", e);
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmountP("");
            setSecondaryAmountP("");
            setPrimaryAmountPMin("");
            setSecondaryAmountPMin("");
            setPoolValue1("");
            setPoolValue2("");
            setSelectPoolToken1("");
            setSelectPoolToken2("");
            setLiquidityAmount("");
            setLiquidityAmountActual(0);
            props.toggleLoader({
                message: "Add Redeem Failed!",
                status: false,
            });
            EventBus.publish("error", `Unable To Redeem Liquidity!`);
        }
    }

    async function handleSwap() {
        try {

            let publicAddress = (await web3.currentProvider.enable())[0];

            if (typeof publicAddress === "undefined" || publicAddress == null) {
                EventBus.publish("error", `Please connect your metamask!`);
                return;
            }

            let HBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();
            let WHBAR = "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase();

            if (selectSwapToken1 == "HBAR") {
                primaryToken = HBAR;
            } else if (selectSwapToken1 == "WHBAR") {
                primaryToken = WHBAR;
            }

            if (selectSwapToken2 == "HBAR") {
                secondaryToken = HBAR;
            } else if (selectSwapToken2 == "WHBAR") {
                secondaryToken = WHBAR;
            }

            if (primaryToken == "") {
                EventBus.publish("error", `Token A is missing!`);
                return;
            }
            if (secondaryToken == "") {
                EventBus.publish("error", `Token B is missing!`);
                return;
            }
            if (primaryToken.toLowerCase() === secondaryToken.toLowerCase()) {
                EventBus.publish(
                    "error",
                    `Token A cannot have same address as Token B!`
                );
                return;
            }
            if (parseFloat(primaryAmount) <= 0) {
                EventBus.publish("error", `Enter Token A Amount!`);
                return;
            }

            primaryToken = primaryToken.toLowerCase();
            secondaryToken = secondaryToken.toLowerCase();

            let primaryContract = new web3.eth.Contract(ERC20ABI, primaryToken);

            if (primaryToken == HBAR || primaryToken == WHBAR) {
                // primaryAmount = web3.utils.toWei(primaryAmount.toString(), "ether");
                primaryAmount = (primaryAmount * 10 ** 8).toString();
            } else {
                let decimals = await primaryContract.methods.decimals().call();
                if (decimals == 18) {
                    primaryAmount = web3.utils.toWei(primaryAmount.toString(), "ether");
                } else {
                    primaryAmount = (primaryAmount * 10 ** decimals).toString();
                }
            }

            if (primaryToken == HBAR || primaryToken == WHBAR) {
                let chechPair = await HBurnFactoryContract.methods
                    .getPair(WHBAR, secondaryToken)
                    .call();
                if (chechPair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish(
                        "error",
                        `Pair Does not exist for Token-A & Token-B!`
                    );
                    return;
                }
            } else if (secondaryToken == HBAR || secondaryToken == WHBAR) {
                let chechPair = await HBurnFactoryContract.methods
                    .getPair(primaryToken, WHBAR)
                    .call();
                if (chechPair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish(
                        "error",
                        `Pair Does not exist for Token-A & Token-B!`
                    );
                    return;
                }
            } else {
                let chechPair = await HBurnFactoryContract.methods
                    .getPair(primaryToken, secondaryToken)
                    .call();
                if (chechPair == "0x0000000000000000000000000000000000000000") {
                    EventBus.publish(
                        "error",
                        `Pair Does not exist for Token-A & Token-B!`
                    );
                    return;
                }
            }

            if (selectSwapToken1 == "WHBAR" || selectSwapToken1 == "HBAR") {
                if (typeof publicAddress === "undefined" || publicAddress == null) {
                    EventBus.publish("error", `Please connect your metamask!`);
                    return;
                } else {
                    let primaryBalance = await web3.eth.getBalance(publicAddress);
                    if (parseFloat(primaryAmount) > primaryBalance) {
                        EventBus.publish("error", `Insufficient Balance!`);
                        return;
                    }
                }
            } else {
                if (typeof publicAddress === "undefined" || publicAddress == null) {
                    EventBus.publish("error", `Please connect your metamask!`);
                    return;
                } else {
                    let primaryContract = new web3.eth.Contract(ERC20ABI, primaryToken);
                    let primaryBalance = await primaryContract.methods
                        .balanceOf(publicAddress)
                        .call();
                    if (parseFloat(primaryAmount) > primaryBalance) {
                        EventBus.publish("error", `Insufficient ${pSymbol} Balance!`);
                        return;
                    }
                }
            }

            // Perform Swap
            const amountInU = primaryAmount;
            const amountIn = amountInU.toString();

            /** Swap **/
            props.toggleLoader({
                message: "Swap in Progress...",
                status: true,
            });

            if (
                primaryToken.toLowerCase() !== "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                console.log("Approving Primary Token ...");
                await primaryContract.methods
                    .approve(HBurnRouterAddress, amountIn)
                    .send({
                        from: publicAddress,
                        gas: 2000000,
                    });
            }

            let mAmount = (parseFloat(secondaryAmount) / 100) * parseFloat(slippageValue);
            let minAmount = secondaryAmount - mAmount;
            let secondaryContract = new web3.eth.Contract(ERC20ABI, secondaryToken);

            if (secondaryToken == HBAR || secondaryToken == WHBAR) {
                minAmount = (minAmount * 10 ** 8).toString();
            } else {
                let decimals = await secondaryContract.methods.decimals().call();
                if (decimals == 18) {
                    minAmount = web3.utils.toWei(minAmount.toString(), "ether");
                } else {
                    minAmount = (minAmount * 10 ** decimals).toString();
                }
            }

            if (
                primaryToken.toLowerCase() === "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                let path = [primaryToken.toLowerCase(), secondaryToken.toLowerCase()];
                console.log("Performing Swap...");
                console.log("*** web3.utils.toHex(amountIn) :: ", web3.utils.toHex(amountIn));
                console.log("*** minAmount :: ", minAmount);
                console.log("*** path :: ", path);
                console.log("*** publicAddress :: ", publicAddress);
                console.log("*** deadlineValue :: ", web3.utils.toHex(Math.ceil(deadlineValue / 1000)));
                let result = await HBurnRouterContract.methods.swapExactETHForTokens(
                    1,
                    path,
                    publicAddress,
                    web3.utils.toHex(Math.ceil(deadlineValue / 1000))
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        value: web3.utils.toHex(amountIn),
                        to: HBurnRouterAddress,
                        data: result.encodeABI(),
                    })
                    .on('transactionHash', hash => {
                        console.log(`************** hash = ${hash}`);
                        setTxHash(hash);
                    })
                    .on('receipt', async receipt => {
                        refreshBalance();
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setSelectSwapToken1("");
                        setSelectSwapToken2("");
                        setPSymbol("");
                        setSSymbol("");
                        // setMicroeconomySuccessModal(true);
                        props.toggleLoader({ status: false });
                        EventBus.publish("success", `Swapped Successfully`);
                    });
            } else if (
                secondaryToken.toLowerCase() === "0x3d371d3fe41ee35d366dbeb08c53379605e4895b".toLowerCase()
            ) {
                let path = [primaryToken.toLowerCase(), secondaryToken.toLowerCase()];
                console.log("Performing Swap...");
                console.log("*** web3.utils.toHex(amountIn) :: ", web3.utils.toHex(amountIn));
                console.log("*** minAmount :: ", minAmount);
                console.log("*** path :: ", path);
                console.log("*** publicAddress :: ", publicAddress);
                console.log("*** deadlineValue :: ", web3.utils.toHex(Math.ceil(deadlineValue / 1000)));
                let result = await HBurnRouterContract.methods.swapExactTokensForETH(
                    web3.utils.toHex(amountIn),
                    1,
                    path,
                    publicAddress,
                    web3.utils.toHex(Math.ceil(deadlineValue / 1000))
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        to: HBurnRouterAddress,
                        data: result.encodeABI(),
                    })
                    .on('transactionHash', hash => {
                        console.log(`************** hash = ${hash}`);
                        setTxHash(hash);
                    })
                    .on('receipt', async receipt => {
                        refreshBalance();
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setSelectSwapToken1("");
                        setSelectSwapToken2("");
                        setPSymbol("");
                        setSSymbol("");
                        // setMicroeconomySuccessModal(true);
                        props.toggleLoader({ status: false });
                        EventBus.publish("success", `Swapped Successfully`);
                    });
            } else {
                let path = [primaryToken.toLowerCase(), secondaryToken.toLowerCase()];
                console.log("Performing Swap...");
                console.log("*** web3.utils.toHex(amountIn) :: ", web3.utils.toHex(amountIn));
                console.log("*** minAmount :: ", minAmount);
                console.log("*** path :: ", path);
                console.log("*** publicAddress :: ", publicAddress);
                console.log("*** deadlineValue :: ", web3.utils.toHex(Math.ceil(deadlineValue / 1000)));
                let result = await HBurnRouterContract.methods.swapExactTokensForTokens(
                    web3.utils.toHex(amountIn),
                    1,
                    path,
                    publicAddress,
                    web3.utils.toHex(Math.ceil(deadlineValue / 1000))
                );

                await web3.eth
                    .sendTransaction({
                        from: publicAddress,
                        to: HBurnRouterAddress,
                        data: result.encodeABI(),
                    })
                    .on('transactionHash', hash => {
                        console.log(`************** hash = ${hash}`);
                        setTxHash(hash);
                    })
                    .on('receipt', async receipt => {
                        refreshBalance();
                        setPrimaryToken("");
                        setSecondaryToken("");
                        setSelectSwapToken1("");
                        setSelectSwapToken2("");
                        setPSymbol("");
                        setSSymbol("");
                        // setMicroeconomySuccessModal(true);
                        props.toggleLoader({ status: false });
                        EventBus.publish("success", `Swapped Successfully`);
                    });
            }
        } catch (e) {
            console.log("********Error = ", e);
            setPSymbol("");
            setSSymbol("");
            setPrimaryToken("");
            setSecondaryToken("");
            setPrimaryAmount("");
            setSecondaryAmount("");
            setSelectSwapToken1("");
            setSelectSwapToken2("");
            // setMicroeconomyErrorModal(true);
            props.toggleLoader({
                message: "Swap Failed!",
                status: false,
            });
            EventBus.publish("error", `Unable To Swap Tokens!`);
        }
    }

    async function closeSuccessModal() {
        setPSymbol("");
        setSSymbol("");
        setPrimaryToken("");
        setSecondaryToken("");
        setPrimaryAmount("");
        setSecondaryAmount("");
        // setMicroeconomySuccessModal(false)
    }

    async function copiedAddress() {
        EventBus.publish("success", "Transaction Hash Copied");
    }

    async function setLiquidityPercent(value) {
        let liquidityValue = (parseFloat(liquidityAmountActual) / 100) * parseInt(value);
        setLiquidityAmount(parseFloat(liquidityValue));
    }

    const handleCopyText = () => {
        navigator.clipboard.writeText(txHash)
            .then(() => EventBus.publish("success", "Transaction Hash Copied"))
            .catch((error) => console.log('Copy failed:', error));
    };

    const [activeIndex, setActiveIndex] = useState(null);

    // Function to handle click and toggle the active class
    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    // console.log("******************** primaryAmount", primaryAmount);

    return (
        <div className="wrapper">
            <Navbar />

            <section className="explore swap">
                <div className="container">
                    <div className="inner">
                        <div className="swap-head">
                            <h3>Swap</h3>

                            <p onClick={() => setShowSettings(true)}>Settings <img className="ml-1" src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/swap-setting.svg" alt="..." /></p>

                            {showSettings && (
                                <div className="dropdown-wrapper">
                                    <button className="close-btn" onClick={() => setShowSettings(false)}>
                                        <img
                                            src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                                        />
                                    </button>

                                    <ul className="dropdown-list">
                                        <li>
                                            <label for="tolerance">Slippage tolerance
                                            </label>

                                            <div className="form-group">
                                                <div className="input-wrap">
                                                    <input
                                                        type="text"
                                                        name="slippageValue"
                                                        value={slippageValue}
                                                        placeholder={slippageValue}
                                                        onInput={handleSlippage}
                                                    />
                                                    <button className="common-btn" onClick={() => autoSlippage()}>Auto <img className="swap-icon" src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/save.png" alt="..." />  </button>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <label for="transaction">Transaction deadline</label>

                                            <div className="form-group">
                                                <input
                                                    onWheel={handleOnWheel}
                                                    type="number"
                                                    name="iDeadlineValue"
                                                    value={iDeadlineValue}
                                                    placeholder={iDeadlineValue}
                                                    onInput={handleSlippage}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="btn-wrap">
                            <button onClick={() => setAddLiquidityModal(true)}>Add Liquidity</button>
                            <button onClick={() => setRemoveLiquidityModal(true)}>Remove Liquidity</button>
                        </div>

                        <div className="form-group">
                            <div className="input-wrap">
                                {primaryAmount ? (
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name="primaryAmount"
                                        value={primaryAmount}
                                        id="primaryAmount"
                                        placeholder={primaryAmount}
                                        onChange={handleOnInput}
                                    />
                                ) : (
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name="primaryAmount"
                                        value={primaryAmount}
                                        id="primaryAmount"
                                        placeholder="0"
                                        onChange={handleOnInput}
                                    />
                                )}

                                <span onClick={() => setSwapModal1(true)}>
                                    {selectSwapToken1
                                        ? selectSwapToken1
                                        : pSymbol
                                            ? pSymbol
                                            : "Select Token"}
                                </span>
                            </div>
                        </div>

                        <img className="swap-icon" onClick={() => handleFlip()} src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/swap-icon.svg" alt="..." />

                        <div className="form-group">
                            <div className="input-wrap">
                                {secondaryAmount ? (
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        id="price"
                                        name="secondaryAmount"
                                        value={secondaryAmount}
                                        placeholder={secondaryAmount}
                                        onInput={handleInput}
                                        disabled
                                    />
                                ) : (
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        id="price"
                                        name="secondaryAmount"
                                        value={secondaryAmount}
                                        placeholder="Expected Amount"
                                        onInput={handleInput}
                                        disabled
                                    />
                                )}

                                <span onClick={() => setSwapModal2(true)}>
                                    {selectSwapToken2
                                        ? selectSwapToken2
                                        : sSymbol
                                            ? sSymbol
                                            : "Select Token"}
                                </span>
                            </div>
                        </div>

                        <button className="common-btn" onClick={() => handleSwap()}>SWAP <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-swap.svg" alt="..." /></button>
                    </div>
                </div>
            </section>

            <Footer />

            <Modal
                open={swapModal1}
                onClose={() => setSwapModal1(false)}
                classNames={{
                    modal: `common-modal swap-modals select-token`,
                }}
                center
            >

                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setSwapModal1(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="form-wrap">

                        <h2>Select a Token</h2>

                        <label>Search</label>

                        <div className="form-group search">
                            {primaryToken !== "" ? (
                                <input
                                    type="text"
                                    name="primaryToken"
                                    value={primaryToken}
                                    placeholder={primaryToken}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    type="text"
                                    name="primaryToken"
                                    value={primaryToken}
                                    placeholder="Enter Token Address"
                                    onInput={handleOnInput}
                                />
                            )}
                        </div>

                        <div className="btn-wrap">
                            <button onClick={(event) => {
                                event.preventDefault();
                                handleSwapValue1(token);
                            }}>
                                {networkId == 296 || networkId == 295 ? (
                                    <img src={UnknownLogo} className="mr-2" alt="matic" />
                                ) : (
                                    <></>
                                )}
                                {token}
                            </button>
                            {filteredPairs.map((pair, index) => (
                                <button
                                    key={index}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleSwapValue11(pair.address); // or pair.token1Address
                                    }}
                                >
                                    <img className="mr-2" src={getTokenImage(pair.symbol)} alt={pair.symbol} />
                                    {pair.symbol}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={swapModal2}
                onClose={() => setSwapModal2(false)}
                classNames={{
                    modal: `common-modal swap-modals select-token`,
                }}
                center
            >

                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setSwapModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="form-wrap">

                        <h2>Select a Token</h2>

                        <label>Search</label>

                        <div className="form-group search">
                            {secondaryToken !== "" ? (
                                <input
                                    type="text"
                                    name="secondaryToken"
                                    value={secondaryToken}
                                    placeholder={secondaryToken}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    type="text"
                                    name="secondaryToken"
                                    value={secondaryToken}
                                    placeholder="Enter Token Address"
                                    onInput={handleOnInput}
                                />
                            )}
                        </div>

                        <div className="btn-wrap">
                            <button onClick={(event) => {
                                event.preventDefault();
                                handleSwapValue2(token);
                            }}>
                                {networkId == 296 || networkId == 295 ? (
                                    <img src={UnknownLogo} className="mr-2" alt="matic" />
                                ) : (
                                    <></>
                                )}
                                {token}
                            </button>
                            {filteredPairs2.map((pair, index) => (
                                <button
                                    key={index}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleSwapValue22(pair.address); // or pair.token1Address
                                    }}
                                >
                                    <img className="mr-2" src={getTokenImage(pair.symbol)} alt={pair.symbol} />
                                    {pair.symbol}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={poolModal1}
                onClose={() => setPoolModal1(false)}
                classNames={{
                    modal: `common-modal swap-modals select-token`,
                }}
                center
            >

                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setPoolModal1(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="form-wrap">

                        <h2>Select a Token</h2>

                        <label>Search</label>

                        <div className="form-group search">
                            {primaryToken !== "" ? (
                                <input
                                    type="text"
                                    name="primaryToken"
                                    value={primaryToken}
                                    placeholder={primaryToken}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    type="text"
                                    name="primaryToken"
                                    value={primaryToken}
                                    placeholder="Enter Token Address"
                                    onInput={handleOnInput}
                                />
                            )}
                        </div>

                        <div className="btn-wrap">
                            <button onClick={(event) => {
                                event.preventDefault();
                                handlePoolValue1(token);
                            }}>
                                {networkId == 296 || networkId == 295 ? (
                                    <img src={UnknownLogo} className="mr-2" alt="matic" />
                                ) : (
                                    <></>
                                )}
                                {token}
                            </button>
                            {filteredPairs.map((pair, index) => (
                                <button
                                    key={index}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handlePoolValue11(pair.address); // or pair.token1Address
                                    }}
                                >
                                    <img className="mr-2" src={getTokenImage(pair.symbol)} alt={pair.symbol} />
                                    {pair.symbol}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={poolModal2}
                onClose={() => setPoolModal2(false)}
                classNames={{
                    modal: `common-modal swap-modals select-token`,
                }}
                center
            >

                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setPoolModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="form-wrap">

                        <h2>Select a Token</h2>

                        <label>Search</label>

                        <div className="form-group search">
                            {secondaryToken !== "" ? (
                                <input
                                    type="text"
                                    name="secondaryToken"
                                    value={secondaryToken}
                                    placeholder={secondaryToken}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    type="text"
                                    name="secondaryToken"
                                    value={secondaryToken}
                                    placeholder="Enter Token Address"
                                    onInput={handleOnInput}
                                />
                            )}
                        </div>

                        <div className="btn-wrap">
                            <button onClick={(event) => {
                                event.preventDefault();
                                handlePoolValue2(token);
                            }}>
                                {networkId == 296 || networkId == 295 ? (
                                    <img src={UnknownLogo} className="mr-2" alt="matic" />
                                ) : (
                                    <></>
                                )}
                                {token}
                            </button>
                            {filteredPairs2.map((pair, index) => (
                                <button
                                    key={index}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handlePoolValue22(pair.address); // or pair.token1Address
                                    }}
                                >
                                    <img className="mr-2" src={getTokenImage(pair.symbol)} alt={pair.symbol} />
                                    {pair.symbol}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={addliquidityModal}
                onClose={() => setAddLiquidityModal(false)}
                classNames={{
                    modal: `common-modal swap-modals add-remove-liquidity`,
                }}
                center
            >

                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setAddLiquidityModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="form-wrap">

                        <h2>ADD LIQUIDITY</h2>

                        <span className="clear" onClick={() => clearStates()}>Clear all</span>

                        <label>Select Pair</label>

                        <div className="form-group-wrapper">
                            <div>
                                <div className="form-group">
                                    <div className="input-wrap">
                                        {primaryToken !== "" ? (
                                            <input
                                                type="text"
                                                name="primaryToken"
                                                value={primaryToken}
                                                placeholder={primaryToken}
                                                onInput={handleOnInput}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name="primaryToken"
                                                placeholder="0x000"
                                                value={primaryToken}
                                                onInput={handleOnInput}
                                            />
                                        )}

                                        <span onClick={() => setPoolModal1(true)}>
                                            {selectPoolToken1
                                                ? selectPoolToken1
                                                : pSymbol
                                                    ? pSymbol
                                                    : "Select Token"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="form-group">
                                    <div className="input-wrap">
                                        {secondaryToken !== "" ? (
                                            <input
                                                type="text"
                                                name="secondaryToken"
                                                value={secondaryToken}
                                                placeholder={secondaryToken}
                                                onInput={handleOnInput}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name="secondaryToken"
                                                placeholder="0x000"
                                                value={secondaryToken}
                                                onInput={handleOnInput}
                                            />
                                        )}

                                        <span onClick={() => setPoolModal2(true)}>
                                            {selectPoolToken2
                                                ? selectPoolToken2
                                                : sSymbol
                                                    ? sSymbol
                                                    : "Select Token"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <span className="tier">{slippageValue}% Free Tier</span>

                        <label>Deposit Amounts</label>

                        <div className="form-group">
                            {primaryAmountP ? (
                                <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    name="primaryAmountP"
                                    value={primaryAmountP}
                                    id="exampleText0"
                                    placeholder={primaryAmountP}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    name="primaryAmountP"
                                    value={primaryAmountP}
                                    id="exampleText0"
                                    placeholder="Enter Amount"
                                    onInput={handleOnInput}
                                />
                            )}

                            <div className="label">
                                <span>{pSymbol}</span>
                            </div>
                        </div>

                        <div className="form-group">
                            {secondaryAmountP ? (
                                <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    name="secondaryAmountP"
                                    value={secondaryAmountP}
                                    id="exampleText0"
                                    placeholder={secondaryAmountP}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    name="secondaryAmountP"
                                    value={secondaryAmountP}
                                    id="exampleText0"
                                    placeholder="Enter Amount"
                                    onInput={handleOnInput}
                                />
                            )}

                            <div className="label">
                                <span>{sSymbol}</span>
                            </div>
                        </div>

                        {primaryAmountP && secondaryAmountP !== "" ? (
                            <div>
                                <p className="text-[#9E9E9E] text-[14px] mt-2 mb-2">
                                    Note: For min amount divide original price by 10 for each token
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="form-group-wrapper">
                            <div>
                                <label>Min Amount {pSymbol}</label>

                                <div className="form-group inc-dec-wrap">
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name="primaryAmountPMin"
                                        value={primaryAmountPMin}
                                        placeholder={"0.036..."}
                                        onInput={handleOnInput}
                                    />
                                    <img
                                        className="decrement"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            decrementCount();
                                        }}
                                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/dec.svg"
                                        alt=""
                                    />
                                    <img
                                        className="increment"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            incrementCount();
                                        }}
                                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/inc.svg"
                                        alt=""
                                    />
                                    <span>
                                        {sSymbol !== "" && pSymbol !== ""
                                            ? `${pSymbol} Per ${sSymbol}`
                                            : ``}
                                    </span>
                                </div>
                            </div>

                            <div>
                                <label>Min Amount {sSymbol}</label>

                                <div className="form-group inc-dec-wrap">
                                    <input
                                        onWheel={handleOnWheel}
                                        type="number"
                                        name="secondaryAmountPMin"
                                        value={secondaryAmountPMin}
                                        placeholder={"0.036..."}
                                        onInput={handleOnInput}
                                    />
                                    <img
                                        className="decrement"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            decrement();
                                        }}
                                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/dec.svg"
                                        alt=""
                                    />
                                    <img
                                        className="increment"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            increment();
                                        }}
                                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/inc.svg"
                                        alt=""
                                    />
                                    <span>
                                        {sSymbol !== "" && pSymbol !== ""
                                            ? `${sSymbol} Per ${pSymbol}`
                                            : ``}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <button
                            className="common-btn mt-4"
                            onClick={(event) => {
                                event.preventDefault();
                                addLiquidity();
                            }}
                        >ADD <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/save.png" alt="..." />  </button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={removeliquidityModal}
                onClose={() => setRemoveLiquidityModal(false)}
                classNames={{
                    modal: `common-modal swap-modals add-remove-liquidity`,
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setRemoveLiquidityModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <div className="form-wrap">

                        <h2>REMOVE LIQUIDITY</h2>

                        <span className="clear" onClick={() => clearStates()}>Clear all</span>

                        <label>Select Pair</label>

                        <div className="form-group-wrapper">
                            <div>
                                <div className="form-group">
                                    <div className="input-wrap">
                                        {primaryToken !== "" ? (
                                            <input
                                                type="text"
                                                name="primaryToken"
                                                value={primaryToken}
                                                placeholder={primaryToken}
                                                onInput={handleOnInput}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name="primaryToken"
                                                placeholder="0x000"
                                                value={primaryToken}
                                                onInput={handleOnInput}
                                            />
                                        )}

                                        <span onClick={() => setPoolModal1(true)}>
                                            {selectPoolToken1
                                                ? selectPoolToken1
                                                : pSymbol
                                                    ? pSymbol
                                                    : "Select Token"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="form-group">
                                    <div className="input-wrap">
                                        {secondaryToken !== "" ? (
                                            <input
                                                type="text"
                                                name="secondaryToken"
                                                value={secondaryToken}
                                                placeholder={secondaryToken}
                                                onInput={handleOnInput}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                name="secondaryToken"
                                                placeholder="0x000"
                                                value={secondaryToken}
                                                onInput={handleOnInput}
                                            />
                                        )}

                                        <span onClick={() => setPoolModal2(true)}>
                                            {selectPoolToken2
                                                ? selectPoolToken2
                                                : sSymbol
                                                    ? sSymbol
                                                    : "Select Token"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label>Liquidity amounts</label>

                        <div className="form-group liquidity-amount">
                            {liquidityAmount ? (
                                <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    name="liquidityAmount"
                                    value={liquidityAmount}
                                    id="liquidityAmount"
                                    placeholder={liquidityAmount}
                                    onInput={handleOnInput}
                                />
                            ) : (
                                <input
                                    onWheel={handleOnWheel}
                                    type="number"
                                    name="liquidityAmount"
                                    value={liquidityAmount}
                                    id="liquidityAmount"
                                    placeholder="Enter Amount"
                                    onInput={handleOnInput}
                                />
                            )}

                            <span>0xBurn-LP</span>
                        </div>

                        <div className="percentage-btn">
                            <button className="common-btn" onClick={() => setLiquidityPercent(10)}>
                                10%
                            </button>

                            <button className="common-btn" onClick={() => setLiquidityPercent(25)}>
                                25%
                            </button>

                            <button className="common-btn" onClick={() => setLiquidityPercent(50)}>
                                50%
                            </button>

                            <button className="common-btn" onClick={() => setLiquidityPercent(100)}>
                                100%
                            </button>
                        </div>

                        <button
                            className="common-btn mt-4"
                            onClick={(event) => {
                                event.preventDefault();
                                removeLiquidity();
                            }}
                        >Remove <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/remove.png" alt="..." />  </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(Swap);